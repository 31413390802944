import { useEffect, useReducer, useState } from "react";
import { useAlert } from "../components/Alert/AlertContext";
import axiosInstance from "../components/Authorization/client";
import { DEFAULT_PROFILE, DEFAULT_PROFILE_V3, getUserProfile as getGravatarProfile } from "../components/GravatarAPI";
import { useAuth } from "../contexts/AuthContext";
import { ALERT_TYPES } from "../utils/Enum";
import useMusic from "./useMusic";
const axios = axiosInstance;

export function useUser({ id }) {
  const { currentUser } = useAuth();
  const [isCurrentUser, setisCurrentUser] = useState(
    id === currentUser?.uid ? true : false
  );
  const { addAlert } = useAlert();
  const [userId, setUserId] = useState(id);
  const [user, setUser] = useState();
  const { getSong, getNFTokenCollection, getAlbum, getPlaylist } = useMusic();

  useEffect(() => {
    setUserId(id);
    /*function to extract user data 
        
            - Gravatar {
                get gravatar info based on user settings stored in DB
            }
            
            - XRPL data (NFTs, Digital Downloads Payments) 
            {
                getTransaction history from txhash stored in database
            }
            
            - Database info {
                Followers (mutual followers and all for current user account only)


                Following (private and only for current user)
                Catalog {
                    transaction hashes to get digital purchase history
                    compare current nft catalog to transaction hash history // BACKEND API ??
                }
                Subscription plan (CONST BOOL to identify)
                Seperate transactions by type (NFT purchase, subscriptions, digital downloads, tips, comments)

                
             }

        */

    /*
            functions to update user info in database will be made here
        
        */
            return () => {}
  }, [userId]);

  const getProfile = async (uid) => {
    try {
      let isfollowingCUser = {};
      let isCUserfollower = {};

      let gProfile = await getUserProfile(uid);
      if (
        currentUser?.uid && uid
          ? uid !== currentUser?.uid
          : id !== currentUser?.uid
      ) {
        isfollowingCUser = await isFollowing(uid ? uid : id, currentUser?.uid)
          .then((data) => {
            return data;
          })
          .catch((err) => {
            console.error(err);
            return false;
          });
        isCUserfollower = await isFollowing(currentUser?.uid, uid ? uid : id)
          .then((data) => {
            return data;
          })
          .catch((err) => {
            console.error(err);
            return false;
          });
      }

      var artistFollowing = await getArtistsUserFollowing(uid ? uid : id)
        .then((data) => {
          return data;
        })
        .catch((err) => {
          console.error(err);
          return [];
        });
      return {
        ...gProfile,
        ...artistFollowing,
        isFollowing: isfollowingCUser,
        isFollower: isCUserfollower,
      };
    } catch (err) {
      return false;
    }
  };

  const getUserProfile = async (uid) => {
    return axios
      .get(`/api/${uid ? uid : id}/getUserProfile`)
      .then((user) => {
        if (user.data && !user.data?.error) {
          
                return {
                  ...user.data
                };
            
           
        } else {
          return {
            exists: false,
            followers: 0,
            following: 0,
            isArtist: false,
            beta: false,
            ...DEFAULT_PROFILE_V3
          };
        }
      })
      .catch((err) => {
        console.error(err);
        return {
          exists: false,
          followers: 0,
          following: 0,
          isArtist: false,
          beta: false,
          ...DEFAULT_PROFILE
        };
      
      });
  };

  const updateUserProfile = async (email, profile, txInfo) => {
    return axios
      .post(`/api/${email}/updateUserProfile`, { profile: profile, txInfo })
      .then((data) => {
        if (data.data?.error || data.data === false) {
          return false;
        }
        return data.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const enablePushNotifications = async (uid, payload) => {
    return axios
      .post(`/api/notifications/${uid}/enableXummNotifications`, {
        payload: payload,
      })
      .then((notification) => {
        //console.log(notification.data);
        if (notification.data?.error || notification.data === false) {
          return false;
        }
        return notification.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const disablePushNotifications = async (uid, payload) => {
    return axios
      .post(`/api/notifications/${uid}/disableXummNotifications`, {
        payload: payload,
      })
      .then((notification) => {
        if (notification.data?.error || notification.data === false) {
          return false;
        }
        return notification.data;
      })
      .catch((err) => {
        return false;
      });
  };

  const getVisibleUsers = async () => {
    return axios
      .get(`/api/getVisibleUsers`)
      .then((data) => {
        if (data?.data && !data?.data?.error) {
          return data.data;
        }
        return false;
      })
      .catch((err) => {
        return false;
      });
  };

  const followUser = async (uid) => {
    addAlert({ type: ALERT_TYPES.INFO, title: "User Follow", secs: 10 });

    return axios
      .post(`/api/${uid ? uid : id}/follow`, { uid: currentUser?.uid })
      .then((data) => {
        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: "User has been followed",
        });
        return data;
      })
      .catch((err) => {
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Follow Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return false;
      });
  };

  const unfollowUser = async (uid) => {
    addAlert({ type: ALERT_TYPES.INFO, title: "User Unfollow", secs: 10 });

    return axios
      .post(`/api/${uid ? uid : id}/unfollow`, { uid: currentUser?.uid })
      .then((data) => {
        //console.log(data)
        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: "User has been unfollowed",
        });
        return data;
      })
      .catch((err) => {
        addAlert({
          type: ALERT_TYPES.DANGER,
          title: "Follow Error",
          message: "Oops, Something went wrong. Try again.",
        });
        return false;
      });
  };

  const getArtistsUserFollowing = async (uid) => {
    return axios
      .get(`/api/${uid ? uid : id}/getArtistsUserFollowing`)
      .then((data) => {
        if(data?.data?.followedArtists){
          return data.data?.followedArtists;
        }
        return false
      })
      .catch((err) => {
        return false;
      });
  };

  const isFollowing = async (uid, user2) => {
    return axios
      .post(`/api/${uid ? uid : id}/isFollowing`, { uid: user2 })
      .then((data) => {
        // //console.log(`is following? ${data.data}`)
        if (!data.data?.error || !data.data?.message) {
          return data.data;
        }
      })
      .catch((err) => {
        return false;
      });
  };

  const getFollowers = async (uid) => {
    return axios
      .get(`/api/${uid ? uid : id}/getFollowers`)
      .then((data) => {
        console.log(data.data)
        if (data.data?.followers) {
          // //console.log(`followers: ${data.data?.followers}`)
          return data.data.followers;
        }
        return false;
      })
      .catch((err) => {
        return false;
      });
  };


  const getDigitalDownloads = async (uid) => {
    return axios
      .get(`/api/user/${uid}/getDigitalDownloads`)
      .then((data) => {
        if (data.data !== false) {
          return data.data;
        }
        return false;
      })
      .catch((err) => {
        return false;
      });
  };

  const isArtist = async (uid) => {
    return axios
      .get(`/api/${uid ? uid : id}/isArtist`)
      .then((data) => {
        if (data.data) {
          // //console.log(`isArtist: ${data.data}`)
          return data.data;
        }
        return false;
      })
      .catch((err) => {
        return false;
      });
  };

  const getFollowing = async (uid) => {
    return axios
      .get(`/api/${uid ? uid : id}/getFollowing`)
      .then((data) => {
        if (data?.data?.followings) {
          // //console.log(`following: ${data.data.followings}`)
          return data.data.followings;
        }
        return false;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const tipUser = async (tipData) => {
    return axios
      .post(`/api/tipUser`, { ...tipData })
      .then((data) => {
        return data.data;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTip = async (id) => {
     return axios
  .get(`/api/tips/${id}/getTip`)
  .then((tip) => {
    if (!tip.data?.error && tip.data === false) {
      return tip?.data;
    } else {
      return false;
    }
  })
  .catch((err) => {
    console.error(err);
    return err;
  });
  };


  const getUserArtistSubscriptions = async (userId) => {
    return await axios
    .get(`/api/${userId}/getArtistSubscriptions`)
    .then((subs) => {
      if (subs?.data && !subs?.data?.error) {
        return subs.data?.subscriptions;
      } else {
        return false;
      }
    })
    .catch((err) => {
      console.error(err);
      return false;
    });


  }



  const getWatchlist = async (userId) => {
    return await axios
      .get(`/api/user/${userId}/getUserWatchlist`)
      .then((watchlist) => {
        if (watchlist?.data && !watchlist?.data?.error) {
          return watchlist.data;
        } else {
          return [];
        }
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  };

  const getUserWatchlist = async (userId) => {
    let watchlist = await getWatchlist(userId);

    if (watchlist && watchlist?.length > 0) {
      let albumIds = watchlist.filter((list) => list?.albumId);
      let songIds = watchlist.filter((list) => list?.songId);
      let collectionIds = watchlist.filter((list) => list?.collectionId);
      let songs = [];
      let albums = [];
      let collections = [];
      if (songIds.length > 0) {
        for (let i = 0; i < songIds?.length; i++) {
          let song = await getSong(songIds[i].songId)
            .then((data) => {
              return data;
            })
            .catch((err) => {
              console.error(err);
              return;
            });
          songs.push(song);
        }
      }
      if (albumIds.length > 0) {
        for (let i = 0; i < albumIds?.length; i++) {
          let album = await getAlbum(albumIds[i].albumId)
            .then((data) => {
              return data;
            })
            .catch((err) => {
              console.error(err);
              return;
            });
          albums.push(album);
        }
      }
      if (collectionIds.length > 0) {
        for (let i = 0; i < collectionIds.length; i++) {
          let collection = await getNFTokenCollection(
            collectionIds[i].collectionId
          )
            .then((data) => {
              return data;
            })
            .catch((err) => {
              console.error(err);
              return;
            });
          collections.push(collection);
        }
      }
      return {
        songs: songs,
        collections: collections,
        albums: albums,
      };
    }
    return {};
  };

  const getLikes = async (userId) => {
    return axios
      .get(`/api/user/${userId}/getLikes`)
      .then((likes) => {
        if (likes.data && !likes.data?.error) {
          return likes.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        return false;
      });
  };

  const getUserLikes = async (userId) => {
    let likes = await getLikes(userId);

    if (likes && likes?.length > 0) {
      let albumIds = likes.filter((like) => like?.albumId);
      let songIds = likes.filter((like) => like?.songId);
      let collectionIds = likes.filter((like) => like?.collectionId);
      let playlistIds = likes.filter((like) => like?.playlistId);
      let songs = [];
      let albums = [];
      let playlists = [];
      let collections = [];
      if (songIds.length > 0) {
        for (let i = 0; i < songIds.length; i++) {
          let song = await getSong(songIds[i].songId)
            .then((data) => {
              return data;
            })
            .catch((err) => {
              console.error(err);
              return;
            });
          songs.push(song);
        }
      }
      if (albumIds.length > 0) {
        for (let i = 0; i < albumIds.length; i++) {
          let album = await getAlbum(albumIds[i].albumId)
            .then((data) => {
              return data;
            })
            .catch((err) => {
              console.error(err);
              return;
            });
          albums.push(album);
        }
      }
      if (playlistIds.length > 0) {
        for (let i = 0; i < playlistIds.length; i++) {
          let playlist = await getPlaylist(playlistIds[i].playlistId)
            .then((data) => {
              return data;
            })
            .catch((err) => {
              console.error(err);
              return;
            });
          playlists.push(playlist);
        }
      }
      if (collectionIds.length > 0) {
        for (let i = 0; i < collectionIds.length; i++) {
          let collection = await getNFTokenCollection(
            collectionIds[i].collectionId
          )
            .then((data) => {
              return data;
            })
            .catch((err) => {
              console.error(err);
              return;
            });
          collections.push(collection);
        }
      }
      return {
        songs: songs,
        playlists: playlists,
        collections: collections,
        albums: albums,
      };
    }
    return {};
  };

  const getNotifications = async (userId) => {
    return await axios
      .get(`/api/notifications/${userId}/getNotifications`)
      .then((notifications) => {
        if (notifications?.data && !notifications?.data?.error) {
          return notifications.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const validateNFTokenOffer = async (notificationId) => {
    return await axios
      .get(`/api/notifications/${notificationId}/validateNFTokenOffer`)
      .then((notifications) => {
        if (notifications?.data && !notifications?.data?.error) {
          return notifications.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }

  const markNotificationAsRead = async (notificationId) => {
    return await axios
      .get(`/api/notifications/user/${notificationId}/markAsRead`)
      .then((notifications) => {
        if (notifications?.data && !notifications?.data?.error) {
          return notifications.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const createLikeNotifications = async () => {
    return await axios
      .get(`/api/notifications/createLikeNotifications`)
      .then((notifications) => {
        //console.log(notifications?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const createFollowNotifications = async () => {
    return await axios
      .get(`/api/notifications/createFollowsNotifications`)
      .then((notifications) => {
        //console.log(notifications?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const createArtistFollowNotifications = async () => {
    return await axios
      .get(`/api/notifications/createArtistFollowsNotifications`)
      .then((notifications) => {
        //console.log(notifications?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };


  const subscribeToStreamingService = async (uid, txId, payload, months) => {
    return await axios.post(`/api/${uid}/subscribeToStreamingService`, {
      txId: txId,
      payload: payload,
      months: months
    }).then((sub) => {
      if(sub.data && !sub?.data?.error){
        return sub.data
      }
    }).catch(err => {
      return false
    })
  }

  const purchaseSwerlVoucher = async (uid, txId, payload, months) => {
    return await axios.post(`/api/${uid}/purchaseSwerlVoucher`, {
      txId: txId,
      payload: payload,
      months: months
    }).then((sub) => {
      if(sub.data && !sub?.data?.error){
        return sub.data
      }
    }).catch(err => {
      return false
    })
  }

  const redeemSwerlVoucher = async (uid, txId, payload) => {
    return await axios.post(`/api/${uid}/redeemSwerlVoucher`, {
      txId: txId,
      payload: payload,
    }).then((sub) => {
      if(sub.data && !sub?.data?.error){
        return sub.data
      }
    }).catch(err => {
      return false
    })
  }

  const getNFTokens = (userId) => {
    return axios
      .get(`/api/${userId}/getNFTokens`)
      .then((nfts) => {
        if (nfts.data && !nfts.data.error) {
          //console.log(nfts.data);
          return nfts.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getNFTokens1 = (userId) => {
    return axios
      .get(`/api/${userId}/getNFTokens1`)
      .then((nfts) => {
        if (nfts.data && !nfts.data.error) {
          //console.log(nfts.data);
          return nfts.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const validateUserNFTokenOwnerShip = (userId, issuer, taxon) => {
    return axios
      .get(`/api/collection/${userId}/${issuer}/${taxon}/validateUserNFTokenOwnerShip`)
      .then((nfts) => {
        if (nfts.data && !nfts.data.error) {
          return nfts.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const validateUserRoyaltyTrustline = async (userId, royaltyId) => {
    return await axios
    .get(`/api/royalties/${userId}/${royaltyId}/validateUserRoyaltyTrustline`)
    .then((trustline) => {
      if (trustline.data && !trustline.data.error) {
        return trustline.data;
      } else {
        return {error: "something went wrong"};
      }
    })
    .catch((err) => {
      console.error(err);
      return {error: err};
    });
  }

  const likeComment = async(uid, commentId) => {
    addAlert({ type: ALERT_TYPES.INFO, title: "Liking comment", secs: 10 });
    return await axios.get(`/api/comment/${commentId}/${uid}/like`).then(
      like => {
       if(like.data && !like?.data?.error){
        addAlert({
          type: ALERT_TYPES.SUCCESS,
          message: "comment liked",
        });
          return like.data
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Like Error",
          message: like.data.error,
        });
        return false
      }
    ).catch(err => {
      console.error(err);
      addAlert({
        type: ALERT_TYPES.DANGER,
        title: "Like Error",
        message: "Oops, Something went wrong. Try again.",
      });
      return false
    })
  }


  const unlikeComment = async(uid, commentId) => {
    addAlert({ type: ALERT_TYPES.INFO, title: "Removing like", secs: 10 });
    return await axios.get(`/api/comment/${commentId}/${uid}/unlike`).then(
      like => {
       if(like.data && !like?.data?.error){
          return like.data
        }
        addAlert({
          type: ALERT_TYPES.WARNING,
          title: "Like Error",
          message: like.data.error,
        });
        return false
      }
    ).catch(err => {
      console.error(err);
      addAlert({
        type: ALERT_TYPES.DANGER,
        title: "Like Error",
        message: "Oops, Something went wrong. Try again.",
      });
      return false
    })
  }
  

  const hasWhitelist = async(collectionId) => {
    return await axios.get(`/api/collection/${collectionId}/hasWhitelist`).then(
      collections => {
       if(collections.data && !collections?.data?.error){
          return collections.data
        }
        return false
      }
    ).catch(err => {
      return false
    })
  }

  const getWhitelistedCollections = async(uid) => {
    return await axios.get(`/api/${uid}/getWhitelistedCollections`).then(
      collections => {
       if(collections.data && !collections?.data?.error){
          return collections.data
        }
        return false
      }
    ).catch(err => {
      return false
    })
  }

  const getPlaylistsByUser = (userId, page) => {
    return axios
      .get(`/api/user/${userId}/getPlaylists/${page}`)
      .then((data) => {
        if (data.data && !data?.error) {
          return data.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getCollaborativePlaylistsByUser = (userId, page) => {
    return axios
      .get(`/api/user/${userId}/getCollaborativePlaylists/${page}`)
      .then((data) => {
        if (data?.data && !data?.data?.error) {
          return data.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const addSongToPlaylist = (userId, playlistId, songId) => {
    return axios
      .post(
        `/api/playlists/${userId}/${playlistId}/${songId}/addSongToPlaylist`
      )
      .then((data) => {
        if (data && !data?.error) {
          return data.data;
        } else {
          return false;
        }
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const updatePlaylist = (userId, playlistId, playlistInfo) => {
    addAlert({
      title: "Playlist Update",
      type: ALERT_TYPES.INFO,
      secs: 10,
    });
    return axios
      .post(`/api/playlists/${userId}/${playlistId}/updatePlaylist`, {
        playlistInfo: playlistInfo,
      })
      .then((data) => {
        if (data.data && !data.data?.error) {
          addAlert({
            title: "Playlist Update",
            type: ALERT_TYPES.SUCCESS,
            message: "Playlist Update Successful",
          });
          return data.data;
        }
        addAlert({
          title: "Playlist Update",
          type: ALERT_TYPES.DANGER,
          message: "Playlist Update Unsuccessful",
        });
        return false;
      })
      .catch((err) => {
        console.error(err);
        addAlert({
          title: "Playlist Update",
          type: ALERT_TYPES.DANGER,
          message: "Playlist Update Unsuccessful",
        });
        return false;
      });
  };

  const getCollectionsByUser = async (uid, page) => {
    return await axios
      .get(`/api/collections/${uid}/getNFTokenCollections/${page}`)
      .then((collections) => {
        if (collections.data?.error || collections.data === false) {
          return false;
        }
        return collections.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getCollectionWhitelistAddresses = async(collectionId) => {
    return await axios
    .get(`/api/collection/${collectionId}/getWhitelistAddresses`)
    .then((addresses) => {
      if (addresses.data?.error || addresses.data === false) {
        return false;
      }
      return addresses.data;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
};

  const addWhitelistedAddresses = async(collectionId, newList, removeList) => {
    return await axios
    .post(`/api/collection/${collectionId}/addWhitelistedAddresses`, {newList: newList, removeList: removeList})
    .then((whitelist) => {
      if (whitelist.data?.error || whitelist.data === false) {
        return false;
      }
      return whitelist.data;
    })
    .catch((err) => {
      console.error(err);
      return false;
    });
  }

  const purchaseNFToken = ({uid, collectionId, quantity, total, comment, payload, currency, isWhitelist}) => {
    //console.log({uid, collectionId, quantity, total, comment, payload, currency})
    return axios
      .post(`/api/${uid}/${collectionId}/collectionPurchase`, {
        quantity: quantity,
        total: total,
        isWhitelist: isWhitelist,
        currency: currency,
        comment: comment,
        payload: payload
      })
      .then((token) => {
        if (token.data?.error || token.data === false) {
          return false;
        }
        return token.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const giftNFToken = ({uid, collectionId, quantity, total, comment, payload, currency, giftee, isPurchase, txId}) => {
    //console.log({uid, collectionId, quantity, total, comment, payload, currency})
    return axios
      .post(`/api/${uid}/${collectionId}/giftCollection`, {
        quantity: quantity,
        total: total,
        currency: currency,
        giftee: giftee,
        txId: txId,
        isPurchase: isPurchase,
        comment: comment,
        payload: payload
      })
      .then((token) => {
        if (token.data?.error || token.data === false) {
          return false;
        }
        return token.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getNFTokenDetails = (nftokenId) => {
    return axios.get(`/api/collection/${nftokenId}/getNFTokenDetails`)
      .then((token) => {
        if (token?.data && !token?.data?.error) {
          return token.data;
        }
        return false
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const acceptNFTokenSellOffer = async(uid, offer) => {
    return await axios
      .get(`/api/${uid}/${offer}/acceptSellOffer`)
      .then((payload) => {
        //console.log(payload.data)
        if (payload.data?.error || payload.data === false) {
          return false;
        }
        return payload.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }

  const postComment = async({
    userId,
    type,
    invoice,
    contentId,
    comment,
    parentId,
    payload,
  }) => {
    return await axios
      .post(`/api/comments/${type}/${contentId}/${userId}/postComment`, {
        parentId: parentId,
        comment: comment,
        invoice: invoice,
        payload: payload,
      })
      .then((post) => {
        //console.log(post.data)
        if (post.data?.error || post.data === false) {
          return false;
        }
        return post.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  };

  const getUserSwerlVouchers = async(uid) => {
    return axios
      .get(`/api/${uid}/getSwerlVouchers`)
      .then((token) => {
        if (token.data?.error || token.data === false) {
          return false;
        }
        return token.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }

  const validateWhitelist = async(uid, collectionId) => {
    return await getWhitelistedCollections(uid).then(collections => {
      if(collections?.length > 0 && collections?.some(c => c?.collectionId === collectionId)){
       return true
      }
      return false
    }) 
  }

  const getRedeemableDiscounts = async(uid, collectionId, discounts) => {
    let redeemables = []
    let nfTokens = []
   return await getDigitalDownloads(uid).then(async(data) => {
      let digitals = {}
      if(data){
        digitals = data
        let songs = digitals?.songs
        let albums = digitals?.albums
        if(songs?.length > 0){
          discounts?.songs?.forEach(s => {
            if(songs.some(song => song?.songId === s.songId)){
              redeemables.push(s?.percentage)
            }
          })
        }
        if(albums?.length > 0){
          discounts?.albums?.forEach(a => {
            if(albums.some(album => album?.albumId === a.albumId)){
              redeemables.push(a?.percentage)
            }
          })
        }
      }
      await getWhitelistedCollections(uid).then(collections => {
        if(collections?.length > 0 && collections?.some(c => c?.collectionId === collectionId)){
          redeemables.push(100)
        }
        return
      }) 
      await getNFTokens(uid).then(tokens => {
        if(tokens?.length > 0 && discounts?.nftokens?.length > 0){
          discounts.nftokens.forEach(element => {
            let numTokens = 0
            tokens.map(t => {
              if(t?.nftoken?.Issuer === element?.issuer && t?.nftoken?.NFTokenTaxon === element?.taxon){
              numTokens = t?.tokens.length
              return t
            }
            return t
          })
            if(numTokens >= element?.numTokens){
              redeemables.push(element?.percentage)
            }
            }
          );
      }})
      return redeemables
    })
      
  }

  const createMarketingCampaign = async(info) => {
    return await axios
      .post(`/api/${info?.userId}/createCampaign`, {
        ...info
      })
      .then((res) => {
        //console.log(res.data)
        if (res.data?.error || res.data === false) {
          return false;
        }
        return res.data;
      })
      .catch((err) => {
        console.error(err);
        return false;
      });
  }


  //Statistics


  const getUserWatchlistsByDate = async (uid, filter) => {
    return axios.get(`/api/stats/user/${uid}/${filter}/getWatchlists`)
    .then(watchlists => {
        if(!watchlists.data || watchlists.data.error){
            return false
        }
        return watchlists.data
    }).catch(err => {
        console.error(err)
        return false
    })
}
  const getUserLikesByDate = async (uid, filter) => {
    return axios.get(`/api/stats/user/${uid}/${filter}/getLikes`)
    .then(likes => {
        if(!likes.data || likes.data.error){
            return false
        }
        return likes.data
    }).catch(err => {
        console.error(err)
        return false
    })
}
  const getUserPlaylistCreationsByDate = async (uid, filter) => {
    return axios.get(`/api/stats/user/${uid}/${filter}/getPlaylistCreations`)
    .then(playlists => {
        if(!playlists.data || playlists.data.error){
            return false
        }
        return playlists.data
    }).catch(err => {
        console.error(err)
        return false
    })
}
  const getUserRoyaltyPurchasesByDate = async (uid, filter) => {
    return axios.get(`/api/stats/user/${uid}/${filter}/getRoyaltyPurchases`)
    .then(purchases => {
        if(!purchases.data || purchases.data.error){
            return false
        }
        return purchases.data
    }).catch(err => {
        console.error(err)
        return false
    })
}
  const getUserNFTokenPurchasesByDate = async (uid, filter) => {
    return axios.get(`/api/stats/user/${uid}/${filter}/getNFTokenPurchases`)
    .then(purchases => {
        if(!purchases.data || purchases.data.error){
            return false
        }
        return purchases.data
    }).catch(err => {
        console.error(err)
        return false
    })
}
  const getUserDigitalPurchasesByDate = async (uid, filter) => {
    return axios.get(`/api/stats/user/${uid}/${filter}/getDigitalPurchases`)
    .then(purchases => {
        if(!purchases.data || purchases.data.error){
            return false
        }
        return purchases.data
    }).catch(err => {
        console.error(err)
        return false
    })
}
  const getUserMusicPlaysByDate = async (uid, filter) => {
    return axios.get(`/api/stats/user/${uid}/${filter}/getPlays`)
    .then(plays => {
        if(!plays.data || plays.data.error){
            return false
        }
        return plays.data
    }).catch(err => {
        console.error(err)
        return false
    })
}


  return {
    followUser,
    unfollowUser,
    isArtist,
    postComment,
    getVisibleUsers,
    getArtistsUserFollowing,
    getUserProfile,
    getUserWatchlist,
    getUserLikes,
    getDigitalDownloads,
    isFollowing,
    getFollowers,
    getFollowing,
    getProfile,
    updateUserProfile,
    getNotifications,
    validateNFTokenOffer,
    markNotificationAsRead,
    getPlaylistsByUser,
    getCollaborativePlaylistsByUser,
    addSongToPlaylist,
    updatePlaylist,
    tipUser,
    getUserArtistSubscriptions,
    subscribeToStreamingService,
    purchaseSwerlVoucher,
    redeemSwerlVoucher,
    //getTips,
    createMarketingCampaign,
    validateUserNFTokenOwnerShip,
    validateUserRoyaltyTrustline,
    getTip,
    getNFTokens,
    getNFTokens1,
    purchaseNFToken,
    getRedeemableDiscounts,
    validateWhitelist,
    giftNFToken,
    getWhitelistedCollections,
    getCollectionsByUser,
    hasWhitelist,
    getCollectionWhitelistAddresses,
    addWhitelistedAddresses,
    getNFTokenDetails,
    getUserSwerlVouchers,
    acceptNFTokenSellOffer,
    enablePushNotifications,
    disablePushNotifications,
    unlikeComment,
    likeComment,
    getUserLikesByDate,
    getUserWatchlistsByDate,
    getUserPlaylistCreationsByDate,
    getUserRoyaltyPurchasesByDate,
    getUserNFTokenPurchasesByDate,
    getUserDigitalPurchasesByDate,
    getUserMusicPlaysByDate
  };
}
