import styled from "styled-components"
import { hexToRgba } from "../HexToRGBA"


export const HubContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
min-height: 100vh;
color: ${props => props.theme.secondaryBg};
padding: 1%;
justify-content: flex-start;
`




export const HubSideBar = styled.div`
display: flex;
flex-direction: column;
align-content: space-evenly;
flex: 1 1 25%;
overflow-y: auto;

margin-right: 1%;
`

export const HubMenuListWrapper = styled.li`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1%;
    height: 4rem;
    cursor: pointer;
    align-items: center;
    overflow: hidden;
    color: ${props => props.theme.secondaryBg};
    border-bottom: 1px solid ${props => props?.theme?.secondaryBg};
    justify-content: space-around;
    justify-items:: space-around;
    background-color: ${props => props?.isActive && props?.theme?.mainColor };
     &:hover {
        background-color: ${props => hexToRgba(props.theme.mainColor, 0.6)}; 
    }
`

export const HubBodyContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
flex: 1 1 70%;
padding: 0.5%;
`
export const HubBodyHeader = styled.span`
display: flex;
padding: 2%;
align-content: center;
align-items: center;
justify-content: space-between;
font-weight: 900;
font-size: 1.2rem;
border-bottom: 0.4px solid ${props => props?.theme?.mainColor};
color: ${props => props?.theme.secondaryBg};

`
export const HubBody = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
max-height:90;
overflow: hidden;
overflow-y: auto;
padding: 1%;

`