import React, { useState, useEffect } from 'react';
import { useWebsiteTheme } from '../WebsiteTheme/WebsiteThemeContext';
import { stringFormatter } from '../../utils';

function MessageWithPreview({ message }) {
  const [messageWithPreviews, setMessageWithPreviews] = useState(null);
  const{ themeColors} = useWebsiteTheme()

  useEffect(() => {
    const convertLinksToPreviews = async (text) => {
      const urlRegex = /(https?:\/\/[^\s]+)/g; // Regular expression to find URLs
      const urls = text.match(urlRegex) || [];

      let newText = text;

      for (const url of urls) {
        try {
          const preview = await getLinkPreview(url); // Function to fetch preview data (explained below)
          if (preview) {
            const previewHtml = `
              <div class="link-preview">
                <a style="color: ${themeColors?.mainBg};" href="${url}" target="_blank" rel="noopener noreferrer">
                  <img src="${preview.image}" alt="${preview.title}" />
                  <h3>${preview.title}</h3>
                  <p>${preview.description}</p>
                </a>
              </div>
            `;
            newText = newText.replace(url, previewHtml);
          } else {
            // If preview fetch fails, just keep the original link
             newText = newText.replace(url, `<a style="color: ${themeColors?.mainBg};" href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`);
          }
        } catch (error) {
          console.error("Error fetching preview:", error);
           newText = newText.replace(url, `<a style="color: ${themeColors?.mainBg};" href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`); // Fallback to regular link on error
        }
      }

      return stringFormatter(newText);
    };

    convertLinksToPreviews(message)
      .then(result => setMessageWithPreviews(result));
  }, [message]);


  const getLinkPreview = async (url) => {
    // Option 1: Using a server-side API (Recommended for production)
    // You'll need to set up a server-side function (e.g., using Node.js, Python, etc.)
    // that fetches the preview data using a library like 'link-preview-js' or similar.
    // This approach avoids CORS issues and keeps your API keys secure.

    // Example using fetch to your server-side API:
    try {
      const response = await fetch(`/api/preview?url=${encodeURIComponent(url)}`); // Replace with your API endpoint
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data; // { title, description, image }

    } catch (error) {
      console.error("Error fetching preview from API:", error);
      return null;
    }


    // Option 2: Client-side using a third-party API (Less reliable, potential CORS issues)
    // Several services offer link preview APIs.  You'll need an API key.  This example uses one as a placeholder.
    // Be very careful about exposing API keys in client-side code in production.

    //  const apiKey = 'YOUR_LINK_PREVIEW_API_KEY'; // Replace with your actual API key
    //  const apiUrl = `https://api.example-link-preview.com/?url=${encodeURIComponent(url)}&key=${apiKey}`; // Replace with the API endpoint

    //  try {
    //      const response = await fetch(apiUrl);
    //      if (!response.ok) {
    //        throw new Error(`HTTP error! status: ${response.status}`);
    //      }
    //      const data = await response.json();
    //      return {
    //          title: data.title,
    //          description: data.description,
    //          image: data.image,
    //      };
    //  } catch (error) {
    //      console.error("Error fetching preview:", error);
    //      return null;
    //  }

  };

  return (
    <>
      {messageWithPreviews ? (
        <text dangerouslySetInnerHTML={{ __html: messageWithPreviews }} /> // Important: Use dangerouslySetInnerHTML with caution!
      ) : (
        message // Show the original message while fetching previews
      )}
    </>
  );
}

export default MessageWithPreview;