import React from 'react'
import { TOSContainer, TOSContentWrapper, TOSHeading, TOSPointsContainer, TOSPointsText, TOSText, TOSTitle } from './TermsOfServiceElements'
import { antiFraudPolicy, cookiePolicy, privacyPolicy, tsAndCs } from './Data'
import { VideoUtils } from '../../utils/ImageUtils'
import { Container, VideoBg, ContentWrapper as CW } from '../SignUp/SignUpElements'
import ContentWrapper from '../ContentWrapper'

const TermsOfService = () => {
     const {video1} = VideoUtils()
  return (
    <Container>
    <VideoBg autoPlay loop muted src={video1}
                  type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
              </VideoBg>
              <ContentWrapper>
              <CW>
        <TOSTitle>Sonar Muse Terms and Conditions</TOSTitle>
        {tsAndCs.map((content, index) => 
            {return(
                <TOSContentWrapper>
                    <TOSHeading>{content?.title}</TOSHeading>
                    {content?.text && <TOSText>{content?.text}</TOSText>}
                    {content?.bold_text && <TOSText><b>{content?.bold_text}</b></TOSText>}
                    {content?.texts && content?.texts.map(text => {return (<TOSText>{text}</TOSText>)})}
                    {content?.points && <TOSPointsContainer>
                        {content?.points?.map(point => {
                            return <TOSPointsText>
                            {point}
                            </TOSPointsText>
                        })}
                        </TOSPointsContainer>}
                </TOSContentWrapper>
            )})}


    </CW>
    </ContentWrapper>
    </Container>
  )
}

export const PrivacyPolicy = () => {
   const {video1} = VideoUtils()
        return (
            <Container>
    <VideoBg autoPlay loop muted src={video1}
                  type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
              </VideoBg>
              <ContentWrapper>
              <CW>
                <TOSTitle>Sonar Muse Privacy Policy</TOSTitle>
                <TOSHeading>Last Updated: 12 March 2025 </TOSHeading>
                {privacyPolicy.map((content, index) => 
                    {return(
                        <TOSContentWrapper>
                            <TOSHeading>{content?.title}</TOSHeading>
                            {content?.text && <TOSText>{content?.text}</TOSText>}
                            {content?.bold_text && <TOSText><b>{content?.bold_text}</b></TOSText>}
                            {content?.texts && content?.texts.map(text => {return (<TOSText>{text}</TOSText>)})}
                            {content?.points && <TOSPointsContainer>
                                {content?.points?.map(point => {
                                    return <TOSPointsText>
                                    {point}
                                    </TOSPointsText>
                                })}
                                </TOSPointsContainer>}
                        </TOSContentWrapper>
                    )})}
        
        
            </CW>
            </ContentWrapper>
            </Container>
    )
}

export const AntiFraudPolicy = () => {
   const {video1} = VideoUtils()
        return (
            <Container>
    <VideoBg autoPlay loop muted src={video1}
                  type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
              </VideoBg>
              <ContentWrapper>
              <CW>
                <TOSTitle>Sonar Muse Anti-Fraud Policy</TOSTitle>
                <TOSHeading>Last Updated: 12 March 2025 </TOSHeading>
                {antiFraudPolicy.map((content, index) => 
                    {return(
                        <TOSContentWrapper>
                            <TOSHeading>{content?.title}</TOSHeading>
                            {content?.text && <TOSText>{content?.text}</TOSText>}
                            {content?.bold_text && <TOSText><b>{content?.bold_text}</b></TOSText>}
                            {content?.texts && content?.texts.map(text => {return (<TOSText>{text}</TOSText>)})}
                            {content?.points && <TOSPointsContainer>
                                {content?.points?.map(point => {
                                     return (<>
                                     {(!point?.text && !point?.points) && <TOSPointsText>
                                    {point}
                                    </TOSPointsText>}
                                    {(point?.text && point?.points) && <><TOSPointsText>{point?.text}</TOSPointsText>
                                    <TOSPointsContainer>{point?.points?.map(p => {
                                        return <TOSPointsText>{p}</TOSPointsText>
                                    })}</TOSPointsContainer></>}
                                    </>) 

                                })}
                                </TOSPointsContainer>}
                        </TOSContentWrapper>
                    )})}
        
        
            </CW></ContentWrapper>
            </Container>
    )
}

export const CookiePolicy = () => {
   const {video1} = VideoUtils()
    return (
        <Container>
    <VideoBg autoPlay loop muted src={video1}
                  type='video/mp4' contentEditable={false} onContextMenu={(e) => {e.preventDefault()}}>
              </VideoBg>
              <ContentWrapper>
              <CW>
        
            <TOSTitle>Sonar Muse Cookie Preferences Policy</TOSTitle>
            <TOSHeading>Last Updated: 12 March 2025 </TOSHeading>
            {cookiePolicy.map((content, index) => 
                {return(
                    <TOSContentWrapper>
                        <TOSHeading>{content?.title}</TOSHeading>
                        {content?.text && <TOSText>{content?.text}</TOSText>}
                        {content?.bold_text && <TOSText><b>{content?.bold_text}</b></TOSText>}
                        {content?.texts && content?.texts.map(text => {return (<TOSText>{text}</TOSText>)})}
                        {content?.points && <TOSPointsContainer>
                            {content?.points?.map(point => {
                                 return (<>
                                 {(!point?.text && !point?.points) && <TOSPointsText>
                                {point}
                                </TOSPointsText>}
                                {(point?.text && point?.points) && <><TOSPointsText>{point?.text}</TOSPointsText>
                                <TOSPointsContainer>{point?.points?.map(p => {
                                    return <TOSPointsText>{p}</TOSPointsText>
                                })}</TOSPointsContainer></>}
                                </>) 

                            })}
                            </TOSPointsContainer>}
                    </TOSContentWrapper>
                )})}
    
    
        </CW>
        </ContentWrapper>
        </Container>
)
}

export default TermsOfService