import React, { useEffect } from 'react'
import CommunityHub from '../../components/CommunityHub'
import { useAuth } from '../../contexts/AuthContext'
import ContentWrapper from '../../components/ContentWrapper'
import OptionModal from '../../components/OptionModal'
import { FormText, FormWrapper } from '../../components/CustomForm/CustomFormElements'
import Messenger from '../../components/Messenger'
import { CustomButton } from '../../components/ButtonElement'
import ScrollToTop from '../../components/ScrollToTop'

const UserCommunityHub = () => {
    const {currentUser, getUserArtistSubscriptions} = useAuth()
    const {goTo} = ScrollToTop()

    useEffect(() => {
      
    
      return () => {
        
      }
    }, [currentUser?.artistSubscriptions])
    
  return (
    <>
    {currentUser && <>
    {currentUser?.artistSubscriptions?.length > 0 &&


        <CommunityHub/>
    }
    {currentUser?.artistSubscriptions?.length === 0 && <>
        <ContentWrapper>
            <FormWrapper>
                <FormText>Subscribe to an artist to gain access to some exclusive music and an opportunity to message them</FormText>
                <CustomButton onClick={() => goTo(`/artists`)}>View Artists</CustomButton>
            </FormWrapper>
        </ContentWrapper>
    
    </>}
    
    </>
    }
    {!currentUser && <>
        <ContentWrapper>
            <FormWrapper>
                <FormText>Subscribe to an artist to gain access to some exclusive music and an opportunity to message them</FormText>
                <CustomButton>Sign Up</CustomButton>
            </FormWrapper>
        </ContentWrapper>
    </>}
    </>
  )
}

export const UserMessenger = () => {
    const {currentUser, getUserArtistSubscriptions} = useAuth()
    const {goTo} = ScrollToTop()
    
    useEffect(() => {
      
    
        return () => {
          
        }
      }, [currentUser?.artistSubscriptions])
    return (
        <>
        {currentUser && <>
        {currentUser?.artistSubscriptions?.length > 0 &&
    
    
            <Messenger/>
        }
        {currentUser?.artistSubscriptions?.length === 0 && <>
            <ContentWrapper>
                <FormWrapper>
                    <FormText>Subscribe to an artist to gain access to some exclusive music and an opportunity to message them</FormText>
                    <CustomButton onClick={() => goTo(`/artists`)}>View Artists</CustomButton>
                </FormWrapper>
            </ContentWrapper>
        
        </>}
        
        </>
        }
        {!currentUser && <>
            <ContentWrapper>
                <FormWrapper>
                    <FormText>Subscribe to an artist to gain access to some exclusive music and an opportunity to message them</FormText>
                </FormWrapper>
            </ContentWrapper>
        </>}
        </>
      )
}

export default UserCommunityHub