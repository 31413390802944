import styled from "styled-components";
import { hexToRgba } from "../HexToRGBA";

export const MessengerWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
color: ${props => props.theme.secondaryBg};
padding: 2%;
width: 100%;
height: 100vh;

`
export const MessengerContainer = styled.div`
display: flex;
flex-direction: row;
width: 100%;
height: 100%;
padding: 1%;
background-color: ${props => hexToRgba(props.theme.secondaryColor,0.4)};
justify-content: flex-start;
border-radius: 15px;



`
export const MessengerSideBar = styled.div`
display: flex;
flex-direction: column;
align-content: space-evenly;
flex: 1 1 25%;
overflow-y: auto;

margin-right: 2%;
`

export const MessengerSideBarHeader = styled.span`
display: flex;
padding: 2%;
align-content: center;
align-items: center;
justify-content: space-between;
font-weight: 900;
font-size: 0.9rem;
color: ${props => props?.theme.secondaryBg};

`
export const MessageSideBarButtonContainer = styled.div`
display: flex;
position: absolute;
bottom: 5%;
width: 10%;
margin-right: 1%;
z-index: 50;
padding: 2%;
gap: 2%;
pointer-events: auto;
white-space: nowrap;
`

export const MessageSideBarButton = styled.div`
display: flex;
border-radius: 50%;
padding: 15%;
font-size: 1.2rem;
color: ${props => props.theme.secondaryBg};
background-color: ${props => props.theme.mainColor};
cursor: pointer;
white-space: nowrap;
`



export const MessengerChatListWrapper = styled.li`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1%;
    height: 4rem;
    cursor: pointer;
    align-items: center;
    overflow: hidden;
    border-bottom: 1px solid ${props => props?.theme?.secondaryBg};
    justify-content: space-around;
    justify-items:: space-around;
    background-color: ${props => props?.isActive && props?.theme?.mainColor };
     &:hover {
        background-color: ${props => hexToRgba(props.theme.mainColor, 0.6)}; 
    }
`
export const MessengerChatListImg = styled.img`
    display: flex;
  align-items: center;
  width: 2rem;
  height: 2rem;
  justify-content: center;
    border-radius: 50%;
    background: ${props => hexToRgba(props.theme.mainBg, 0.9)} url(${props => props.src});
    background-size: cover;
`

export const MessengerChatListContent = styled.div`
    display: flex;
    flex-direction: column;
      align-items: flex-start;
    width: 100%;
    padding: 1%;
    font-size: 0.8rem;
    flex: 1 1 80%;
`
export const MessengerChatListContentTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 800;


`
export const MessengerChatListContentPreview = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex: 1 1 60%;
    white-space: nowwrap;
    overflow: hidden;

`
export const MessengerChatListInfo = styled.div`
    display: flex;
    font-size: 0.6rem;
    align-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1 1 10%;
`

export const MessengerBodyContainer = styled.div`
display: flex;
flex-direction: column;
border: 1px solid ${props => props?.theme?.mainColor};
flex: 1 1 70%;
padding: 0.5%;
border-radius: 8px;
`
export const MessengerBodyHeader = styled.span`
display: flex;
padding: 2%;
align-content: center;
align-items: center;
justify-content: space-between;
font-weight: 900;
font-size: 1.2rem;
border-bottom: 0.4px solid ${props => props?.theme?.mainColor};
color: ${props => props?.theme.secondaryBg};

`
export const MessengerBody = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
max-height:90;
overflow: hidden;
overflow-y: auto;
padding: 1%;

`

export const MessengerTextInputWrapper = styled.div`
    display: flex;
    width: 100%;
    min-height: 8%;
    bottom: 10%;
    padding: 1%;
    background-color: ${props => hexToRgba(props?.theme.secondaryColor,0.3)};
`
export const MessengerTextInputForm = styled.textarea`
    display: flex;
    width: 100%;
    border: none;
    padding: 1%;
    color: ${props => props?.theme?.secondaryBg};
    flex: 1 1 90%;
    resize: none;
    height: 100%;
    background: transparent;
`
export const MessengerTextButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    border: none;
    flex: 1 1 10%;
    font-size: 1.5rem;
    justify-content: space-around;
    align-items: center;
`

export const MessageBubbleContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
    max-width: 80%;
    margin-bottom: 1%;
    white-space: wrap;
    justify-self: ${props => props?.isUser ? 'flex-end': 'flex-start'};
    justify-items: ${props => props?.isUser ? 'flex-end': 'flex-start'};
    align-items: ${props => props?.isUser ? 'flex-end': 'flex-start'};
    align-self: ${props => props?.isUser ? 'flex-end': 'flex-start'};
    @media screen and (max-width: 960px) {
    justify-self: flex-start;
    justify-items: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    width: 80%;
    }
`
export const MessageBubble = styled.div`
    display: flex;
    border-radius: 15px;
    width: 100%;
    font-size: 1rem;
    padding: 5%;
    align-self: ${props => props?.isUser ? 'flex-end': 'flex-start'};
    justify-self: ${props => props?.isUser ? 'flex-end': 'flex-start'};
    background-color: ${props => props?.isUser ? hexToRgba(props?.theme?.mainColor,0.3): props?.broadcast ? hexToRgba(props?.theme?.secondaryColor,0.5) : hexToRgba(props?.theme?.accentColor,0.3)};
    border: ${props => props?.broadcast && `solid 1px ${props?.theme?.mainBg}`};
`


export const EmptyContentText = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    text-align: center;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    color: ${props => hexToRgba(props.theme.secondaryBg, 0.5)};

`