import { addDoc, collection, doc, getDocs, onSnapshot, orderBy, query, updateDoc, where, getDoc, limit } from "firebase/firestore"
import { db, timestamp } from "../../components/Authorization/firebase"
import axiosInstance from "../../components/Authorization/client"
import { filterArtistInfo } from "./artistUtils"
import { timestampToDate } from ".."

export const getUserChats = (userId, setChats, setLoaded) => {
    onSnapshot(query(collection(db, `chats`), where('userId', '==', userId), orderBy('lastMessageDate', 'desc')),
    async (collection) => {
        if(collection.empty){
            setChats([])
            setLoaded(true)
            return 
        }
        let chats = []
        let snapshot = collection.docs.sort((a, b) => new Date(timestampToDate(a?.data()?.lastMessageDate)).getTime() < new Date(timestampToDate(b?.data()?.lastMessageDate) ? 1 : -1).getTime()).reverse()
        for (let i = 0; i < snapshot.length; i++){
            let artistInfo = {
                artistId: snapshot[i].data()?.artistId,
                          artistName: `Artist ${snapshot[i].data()?.artistId?.substring(0, 8)}...`,
                          fullName: `Artist ${snapshot[i].data()?.artistId?.substring(0, 8)}...`,
                          address: false,
                          artistSettings: false,
                          photoUrl:
            
                            `https://secure.gravatar.com/avatar/0000000000000000000`,
                          isVerified: false,
            }
            await axiosInstance.get(`/api/${snapshot[i].data()?.artistId}/getArtist`).then((artist) => {
                if(artist?.data && artist?.data !== false){
                    artistInfo = artist?.data
                }
                }).catch(err => {
                    console.error(err)
            })
            if(snapshot[i].data()?.lastMessage){
                await getDoc(doc(db,`messages/${snapshot[i].data()?.lastMessage}`)).then((message) => {
                    if(!message.exists){
                      return 
                }
               chats.push({
                chatId: snapshot[i]?.id,
                artist: filterArtistInfo(artistInfo?.artistId, artistInfo?.profile),
                ...snapshot[i].data(),
                    lastMessage: message.data()
                })  
            })}else{
                chats.push({
                    chatId: snapshot[i]?.id,
                    artist: artistInfo,
                    ...snapshot[i].data(),
            })
            }
        }
        setChats(chats)
        setLoaded(true)
    })
}

export const getChatBySubscriberId = (subscriberId, artistId, setChat, setLoaded) => {
    onSnapshot(query(collection(db, `chats`), where('artistId', '==', artistId),where('userId', '==', subscriberId), limit(1)),
    async (snapshot)  => {
        if(snapshot.empty){
            setLoaded(true)
        }
        setChat({
            chatId: snapshot.docs[0].id, 
            ...snapshot.docs[0].data()
        })
        setLoaded(true)
    }, (err) => {
        console.error(err)
        setLoaded(true)
    })
}

export const getChatData = (chatId, setChat, setLoaded) => {
    onSnapshot(query(collection(db, `messages`), where('chatId', '==', chatId), orderBy('dateCreated', 'asc')),
    (snapshot) => {
        if(snapshot.empty){
            setLoaded(true)
            return 
        }
        setChat(snapshot.docs.map(chat => {
            return {
                messageId: chat.id,
                ...chat.data()
            }
        }))
        setLoaded(true)
    },(err) => {
        console.error(err)
        setLoaded(true)
    })
}

export const getBroadcasts = (artistId, setBroadcasts, setLoaded) => {
    onSnapshot(query(collection(db, `broadcasts`), where('artistId', '==', artistId), orderBy('dateCreated', 'asc')),
    (snapshot) => {
        if(snapshot.empty){
            setLoaded(true)
            return 
        }
        setBroadcasts(snapshot.docs.map((broadcast) => {
            return {...broadcast.data()}
        }))
        setLoaded(true)
    })
}

export const sendMessage = async(userId, chatId, message, setError) => {
    let now = timestamp.now()
    let messageRef = collection(db, '/messages')
        return await addDoc(messageRef,{
            sender: 'user',
            senderId: userId,
            chatId: chatId,
            message,
            dateCreated: now
        }).then(data => {
            updateDoc(doc(db,`chats/${chatId}`), {
                lastMessage: data.id,
                lastMessageDate: now
            })
            return
        }).catch(err => {
            console.error(err)
            setError(err?.message)
            return
        })
}

export const sendBroadcast = async(artistId, message) => {
    let messageRef = collection(db, '/broadcasts')
        return await addDoc(messageRef,{
            artistId: artistId,
            message,
            dateCreated: timestamp.now()
        }).then(data => {
            return
        }).catch(err => {
            console.error(err)
            return
        })
}

