import  { hexToStr, timestampToDate } from "../index"
import { db} from "../../components/Authorization/firebase"
import {query, doc, getDoc, collection, getDocs, where, limit, orderBy, updateDoc, onSnapshot, deleteDoc} from 'firebase/firestore'
import { PAYOUT_TYPE } from "../Enum"
import { getUserDetails } from "./userUtils"
import { hashEmail } from "../../components/GravatarAPI"
import axiosInstance from "../../components/Authorization/client"


export const getArtistSongs = async (artistId) => {
    return await getDocs(query(collection(db, "songs"), where("artists", "array-contains", artistId), orderBy('releaseDate', 'desc')))
    .then(async (songs) => {
      let songInfo= []
      for(let i = 0; i < songs.docs?.length; i++) {
        let s = songs.docs[i]
        let likes = (await getDocs(query(collection(db, "likes"), where("songId", "==", s.id)))).size
        let purchases = (await getDocs(query(collection(db, "digitalPurchases"), where("songId", "==", s.id)))).size
        songInfo.push({
          songId: s.id,
          ...s.data(),
          likes: likes,
          purchases: purchases,
          releaseDate: `${new Date(timestampToDate(s?.data()?.releaseDate)).getDate()}-${new Date(timestampToDate(s?.data()?.releaseDate)).getMonth() + 1}-${new Date(timestampToDate(s?.data()?.releaseDate)).getFullYear()}`
        }
      )
      
      }
      return songInfo
    })
  }
  
export const getArtistAlbums = async (artistId) => {
    return await getDocs(query(collection(db, "albums"), where("artists", "array-contains", artistId), orderBy('releaseDate', 'desc')))
    .then(async (albums) => {
      let albumInfo= []
      for(let i = 0; i < albums.docs?.length; i++) {
        let a = albums.docs[i]
        let likes = (await getDocs(query(collection(db, "likes"), where("albumId", "==", a.id)))).size
        let purchases = (await getDocs(query(collection(db, "digitalPurchases"), where("albumId", "==", a.id)))).size
        albumInfo.push({
          albumId: a.id,
          ...a.data(),
          likes: likes,
          purchases: purchases,
          releaseDate: `${new Date(timestampToDate(a?.data()?.releaseDate)).getDate()}-${new Date(timestampToDate(a?.data()?.releaseDate)).getMonth() + 1}-${new Date(timestampToDate(a?.data()?.releaseDate)).getFullYear()}`
        }
      )
      
      }
      return albumInfo
    })
  }
  
export const getTopPlayedSongsByArtist = async(artistId) => {
    return await getDocs(query(collection(db, "songs"), where("artists", "array-contains", artistId), limit(10))) //add order by plays desc
    .then(async (songs) => {
      let songInfo= []
      for(let i = 0; i < songs.docs?.length; i++) {
        let s = songs.docs[i]
        let likes = (await getDocs(query(collection(db, "likes"), where("songId", "==", s.id)))).size
        let purchases = (await getDocs(query(collection(db, "digitalPurchases"), where("songId", "==", s.id)))).size
        songInfo.push({
          songId: s.id,
          ...s.data(),
          likes: likes,
          purchases: purchases,
          releaseDate: `${new Date(timestampToDate(s?.data()?.releaseDate)).getDate()}-${new Date(timestampToDate(s?.data()?.releaseDate)).getMonth() + 1}-${new Date(timestampToDate(s?.data()?.releaseDate)).getFullYear()}`
        }
      )
      
      }
      return songInfo
    })
  }

export const getArtistNotifications = async(artistId, setNotifications) => {
    return onSnapshot(query(collection(db, "artistNotifications"), where("artistId", "==", artistId), orderBy('createdAt', 'desc')), notifSnapshot => {
        setNotifications(notifSnapshot.docs.map(n => {
            return {notificationId: n?.id,
                ...n.data()}
        }))
    })
}

export const getArtistFollowers = (artistId, setFollowers) => {
    onSnapshot(query(collection(db, "artistFollows"), where("artistId", "==", artistId)), async(followSnapshot) => {
        let followers = followSnapshot.docs.map(n => {
            return {followId: n?.id,
                ...n.data(),
            }
        })
        var artistFollowers = [];
    for (var i = 0; i <= followers?.length - 1; i++) {
      let artistfollower = await getUserDetails(
        followers[i].followerId
      );
      if(!artistfollower?.uid){
        await deleteDoc(db(`artistFollows/${followers[i].followId}`))
      }else{
      artistFollowers.push({
        email: artistfollower.email,
        photoUrl: artistfollower.photoURL,
        uid: artistfollower.uid,
        dateFollowed: followers[i].dateFollowed,
      });}
    }
    setFollowers(artistFollowers)
    })
}


export const filterArtistInfo = (id, artist) => {
  return {
          artistId: id,
          artistName: artist?.name,
          fullName: artist?.fullName,
          address: artist?.uid,
          artistSettings: artist?.artistSettings,
          profilePicture: artist?.profilePicture?.url ? artist?.profilePicture?.url : undefined, 
          paymentPointer: artist.paymentPointer,
          photoUrl:
            artist?.email &&
            `https://secure.gravatar.com/avatar/${hashEmail(artist.email)}`,
            avatar_url:
            artist?.email &&
            `https://secure.gravatar.com/avatar/${hashEmail(artist.email)}`,
          email: artist?.email ? hashEmail(artist.email): null,
          isVerified: artist?.isVerified,
  }

}


export const getArtistDigitalSales = async(artistId, duration) => {
    let songs = await getArtistSongs(artistId)
    let albums = await getArtistAlbums(artistId)
    let sales = []
    for(let i = 0; i < songs.length; i++){
      let s = await getDocs(query(collection(db, "digitalPurchases"), where("songId", "==", songs[i].songId))).then(purchases =>{
        if(purchases.empty){
          return []
        }
        return purchases.docs.map(p => {
          return {purchaseId: p.id,
          ...p.data()}
        })
      })
      sales.push(...s)
    }
    for(let i = 0; i < albums.length; i++){
      let a = await getDocs(query(collection(db, "digitalPurchases"), where("albumId", "==", albums[i].albumId))).then(purchases =>{
        if(purchases.empty){
          return []
        }
        return purchases.docs.map(p => {
          return {purchaseId: p.id,
          ...p.data()}
        })
      })
      sales.push(...a)
    }
  switch (duration) {
    case "last 24 hours":
      
      break;
    case "last 7 days":
      
      break;
    case "last 30 days":
      
      break;
    case "last 6 months":
      
      break;
    case "last 1 year":
      
      break;
    case "all":
        return sales
      break;
  
    default:
      break;
  }

}

export const getArtistPendingMicropayments = async(artistId, setPendingFunds) => {
  onSnapshot(doc(db,`artists/${artistId}`), async (artistSnapshot) => {
    if(artistSnapshot?.exists){
      if(artistSnapshot.data()?.paymentChannel?.channel){
        return await axiosInstance.get(`/api/${artistId}/${artistSnapshot.data()?.paymentChannel?.channel}/getArtistCurrentBalance`).then((funds) => {
          if(funds.data?.funds){
            return setPendingFunds(funds.data.funds + (artistSnapshot.data()?.pendingFunds ? parseInt(artistSnapshot.data()?.pendingFunds) : 0))
          }
          return setPendingFunds(artistSnapshot.data()?.pendingFunds ? artistSnapshot.data()?.pendingFunds : 0)

        }).catch((err) => {
          return setPendingFunds(artistSnapshot.data()?.pendingFunds ? artistSnapshot.data()?.pendingFunds : 0)
        })
      }
      return setPendingFunds(artistSnapshot.data()?.pendingFunds ? artistSnapshot.data()?.pendingFunds : 0)
    }
    return setPendingFunds(0)
  })
}