import styled from "styled-components"

export const TOSContainer = styled.div`
display: flex;
    min-height: 100vh;
    flex-direction: column;
    width: 100%;
    padding: 1% 3%;
    color: ${props => props?.theme?.secondaryBg};
    font-size: 0.9rem;
    background: ${props => props?.theme?.mainBg};

`

export const TOSTitle = styled.div`
display: flex;
    font-weight: 700;
    font-size: 2rem;
    color: ${props => props?.theme?.secondaryColor};
    margin-bottom: 2%;

`

export const TOSHeading = styled.div`
    font-weight: 700;
    font-size: 1.3rem;
    color: ${props => props?.theme?.secondaryColor};
    margin: 1% 0;
`

export const TOSContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1%;
`

export const TOSText = styled.div`
    display: flex;

`
export const TOSPointsContainer = styled.ul`
list-style-position: outside;
padding: 0 1%;
`
export const TOSPointsText = styled.li`



`