import React, { useEffect, useRef, useState } from 'react'
import { EmptyContentText, MessageBubble, MessageBubbleContainer, MessengerBody, MessengerChatListInfo } from './MessengerElements'
import {useAuth} from '../../contexts/AuthContext'
import { getChatData } from '../../utils/dbUtils/messengerUtils'
import { getChatMessageTimestamp, getChatTime, getChatTimestamp, getTimestamp, timestampToDate } from '../../utils'
import { MessageSkeleton } from '../Skeleton'
import MessageWithPreview from './MessageWithPreview'
import { useWebsiteTheme } from '../WebsiteTheme/WebsiteThemeContext'

export function AutoScrollDiv({chatId, children }) {
    const scrollableDivRef = useRef(null);
    const [hasOverflowY, setHasOverflowY] = useState(false);

    useEffect(() => {
      const checkOverflow = () => {
        if (scrollableDivRef.current) {
          setHasOverflowY(scrollableDivRef.current.scrollHeight > scrollableDivRef.current.clientHeight);
        }
      };
  
      checkOverflow(); // Initial check
  
      // Optionally, re-check on window resize or other events that might change the content's size
      const resizeObserver = new ResizeObserver(entries => {
          checkOverflow();
      });
  
      if (scrollableDivRef.current) {
          resizeObserver.observe(scrollableDivRef.current);
      }
      return () => {
          if(scrollableDivRef.current){
              resizeObserver.unobserve(scrollableDivRef.current);
          }
      };
  
    }, []);

    useEffect(() => {
      if (scrollableDivRef.current && hasOverflowY) {
        scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight;
      }
    }, [hasOverflowY, chatId]); // Re-run effect when children change (content updates)
  
    return (
      <MessengerBody 
        ref={scrollableDivRef}
        
      >
        {children}
      </MessengerBody>
    );
  }


const Messages = ({chatId}) => {
    const {currentUser, currentProfile, profiles} = useAuth()
    const {themeColors} = useWebsiteTheme()
    const [isLoaded, setLoaded] = useState(false)
    const [messages, setMessages] = useState([])

useEffect(() => {
    setLoaded(false)
    setMessages([])
  if(chatId){
    getChatData(chatId, setMessages, setLoaded)
  }
  return () => {

  }
}, [chatId])
  return (
    <>
        {chatId && isLoaded && messages?.length > 0 && messages.map((m, index) => {
            return (
              <>{(index === 0 || (index > 0 && getChatMessageTimestamp(new Date(timestampToDate(m?.dateCreated))) !== getChatMessageTimestamp(new Date(timestampToDate(messages[index-1]?.dateCreated))))) &&
               <div style={{borderRadius: '15px', minWidth: '5rem',  backgroundColor: themeColors?.secondaryColor, alignSelf: 'center', justifySelf: 'center', margin: '1%', padding: '1%' }}>{getChatMessageTimestamp(new Date(timestampToDate(m?.dateCreated)))}</div>}
                <MessageBubbleContainer isUser={currentUser?.uid === m?.senderId} id={m?.messageId}>
                  
                    <MessageBubble isUser={currentUser?.uid === m?.senderId} broadcast={m?.broadcast && true}>
                    <MessageWithPreview message={m?.message}/>
                    
                    </MessageBubble>
                    <MessengerChatListInfo>{getChatTime(new Date(timestampToDate(m?.dateCreated)))}</MessengerChatListInfo>
                </MessageBubbleContainer></>
            )
        })

        }
        {
            !isLoaded && chatId && <MessageSkeleton/>
        }
        {
            !isLoaded && !chatId && <EmptyContentText>
                Select Chat to start conversation
            
            </EmptyContentText>
        }
    </>
  )
}

export default Messages