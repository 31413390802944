import React, { useEffect, useState } from 'react'
import ContentWrapper from '../ContentWrapper'
import { Nav, NavContainer, NavLink } from '../NavLink/NavLinkElements'
import { Slider } from '../Slider'
import { SLIDER_TYPES } from '../../utils/Enum'
import useArtist from '../../hooks/useArtist'
import ScrollToTop from '../ScrollToTop'

const SubscriptionDownload = ({name, artistId, cUser}) => {
    const [isLoaded, setLoaded] = useState(false)
    const {goTo} = ScrollToTop()
    const {searchArtistDownloadableMusic} = useArtist({id: artistId})
    const [discography, setDiscography] = useState([])
    const [currentTab, setTab] = useState('all')

    useEffect(() => {
        console.group(artistId)
      setLoaded(false)
        searchArtistDownloadableMusic(artistId).then((discog) => {
            console.log(discog)
            if(discog){
                setDiscography(discog)
            }
            setLoaded(true)
        })
      return () => {
      }
    }, [artistId])
    

  return (
    <ContentWrapper heading={name} subheading={'Go to Artist Profile'} onClick={() => goTo(`/artist/${artistId}`)}>
    {isLoaded && (discography?.albums?.length > 0 || discography?.songs?.length > 0) && <>
        <NavContainer>
            <Nav>
                <NavLink active={currentTab === 'all'} onClick={() => setTab('all')}>All</NavLink>
                <NavLink active={currentTab === 'songs'} onClick={() => setTab('songs')}>Songs</NavLink>
                <NavLink active={currentTab === 'albums'} onClick={() => setTab('albums')}>Albums</NavLink>
            </Nav>
        </NavContainer>

        <ContentWrapper>
           {currentTab === 'all' && <Slider slides={ () => {
            let albums = discography?.albums?.length > 0 ? discography?.albums : []
            let songs = discography?.songs?.length > 0 ? discography?.songs : []
            return [...albums, ...songs].map((content) => {
                return {
                    ...content,
                    owner: cUser
                }
            })
           }} heightScroll type={SLIDER_TYPES.SUBSCRIPTION_DOWNLOAD} id={'all subscription music'}/>}
           {currentTab === 'albums' && <Slider slides={ () => {
            let albums = discography?.albums?.length > 0 ? discography?.albums : []
            let songs = discography?.songs?.length > 0 ? discography?.songs : []
            return [...albums, ...songs].map((content) => {
                return {
                    ...content,
                    owner: cUser
                }
            })
           }} heightScroll type={SLIDER_TYPES.SUBSCRIPTION_DOWNLOAD} id={'album subscription music'}/>}
           {currentTab === 'songs' && <Slider slides={ () => {
            let songs = discography?.songs?.length > 0 ? discography?.songs : []
            return [...songs].map((content) => {
                return {
                    ...content,
                    owner: cUser
                }
            })
           }} heightScroll type={SLIDER_TYPES.SUBSCRIPTION_DOWNLOAD} id={'song subscriptions music'}/>}
        </ContentWrapper>
    </>}

    {isLoaded && !discography?.albums && !discography?.songs && 
    <>
        No Downloadable Content Found
    </>}

    {!isLoaded && 
        <Slider type={SLIDER_TYPES.MUSIC_SKELETON} />
    }
    
    </ContentWrapper>
  )
}

export default SubscriptionDownload