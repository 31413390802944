import React, { useEffect, useState } from 'react'
import { Header } from '../Header'
import ContentWrapper from '../ContentWrapper'
import { HubBodyContainer, HubContainer, HubMenuListWrapper, HubSideBar } from './CommunityHubElements'
import { useAuth } from '../../contexts/AuthContext'
import useArtist from '../../hooks/useArtist'
import { useModal } from '../Modal/ModalContext'
import { SelectButton, SelectButtonWrapper } from '../Select'
import { Nav, NavContainer, NavLink } from '../NavLink/NavLinkElements'
import ScrollToTop from '../ScrollToTop'
import { getObjectLength, groupInfoByDate } from '../../utils'
import { SubscriberUserList } from '../ContentList/UserList'
import { ContentListSkeleton, UserContentListSkeleton } from '../Skeleton'
import ChartToggle from '../Charts'
import { useWebsiteTheme } from '../WebsiteTheme/WebsiteThemeContext'
import { FormText, FormWrapper } from '../CustomForm/CustomFormElements'
import { ModalLoadingScreen } from '../LoadingScreen'
//import { AlbumsReleasesTable, SinglesReleasesTable } from '../ContentTable/ArtistTables'
import OptionModal from '../OptionModal'
import { HoverOption } from '../MenuOptions/MenuOptionsElements'
import { getChatBySubscriberId } from '../../utils/dbUtils/messengerUtils'
import { ALERT_TYPES, SLIDER_TYPES } from '../../utils/Enum'
import { useAlert } from '../Alert/AlertContext'
import ArtistList from '../ContentList/ArtistList'
import { filterArtistInfo } from '../../utils/dbUtils/artistUtils'
import { Slider } from '../Slider'


const ArtistsInfo = ({artists, isLoaded}) => {
    const {} = useArtist({})
    const {openModal,closeModal,modalContent} = useModal()
    const {themeColors} = useWebsiteTheme()
    const [currentTab, setCurrentTab] = useState('subscribers')
    const [isStatsLoaded, setStatsLoaded] = useState(false)
    const [stats, setStats] = useState({})

    return (
        <><ContentWrapper heading={'Artists Subscriptions'}>
           { isLoaded && artists?.length > 0 && <ArtistList content={artists} onButtonClick={(artist) => {
            openModal()
            modalContent(<ArtistMenuOptions artist={artist}/>)
           }}/>}
        
        
            {!isLoaded && <UserContentListSkeleton/>}
        
            {isLoaded && artists?.length === 0 && 
                <FormWrapper>
                <FormText>No Artists Found</FormText>
              </FormWrapper>

            }
            
        </ContentWrapper>
        </>
    )
}


const ArtistMenuOptions = ({artist})=> {
    const {currentUser, currentProfile, gravatarInfo} = useAuth()
    const {goTo} = ScrollToTop()
    const {addAlert} = useAlert()
    const [chatInfo, setChatInfo] = useState({})
    const {openModal, closeModal, modalContent} = useModal()
    const [isLoaded,setLoaded] = useState(false)

    useEffect(() => {
        setLoaded(false)
      getChatBySubscriberId(currentUser?.uid, artist?.artistId, setChatInfo, setLoaded)
        
      return () => {
       
      }
    }, [currentUser?.uid, artist])
    

    return (
        
        <OptionModal heading={`${artist?.artistName}`} onCancel={() => {
            closeModal()
            modalContent()
        }}>
            {isLoaded &&<><HoverOption onClick={() => {
                goTo(`/artist/${artist?.artistId}?tab=subscription`)
                closeModal()
                modalContent()}
                }>
                View Exclusive Content
            </HoverOption>
           {getObjectLength(chatInfo) > 0 && !chatInfo?.disabled && <HoverOption onClick={() =>{ 
            goTo(`/messageHub?chat=${chatInfo?.chatId}`)
            closeModal()
            modalContent()
            }}>
                Message Artist
            </HoverOption>}</>}
            {!isLoaded && <ModalLoadingScreen text={'Loading Info'}/>}
        
        </OptionModal>
    )

}


const ExclusiveReleases = ({artists,releases, isLoaded}) => {
    const [currentTab, setCurrentTab] = useState('all')
    const [content, setContent] = useState([])



    
    

    const updateContent = () => {
        let c = []
        if(currentTab === 'all'){
            releases.map((release) => {
                    return  Object.values(release).map(r => {
                          if(r?.albums?.length > 0){
                      c.push(...r?.albums?.albums)
                      
                  }
                  if(r?.songs?.length > 0){
                      c.push(...r?.songs?.songs)
    
                  }
                      })
              }) 
          }else{
           
              
                if(releases[releases.findIndex(r => Object.keys(r)[0] === currentTab)][currentTab]?.albums?.length > 0){
                    c.push(...releases[releases.findIndex(r => Object.keys(r)[0] === currentTab)][currentTab]?.albums?.albums)
                    
                }
                if(releases[releases.findIndex(r => Object.keys(r)[0] === currentTab)][currentTab]?.songs?.length > 0){
                    c.push(...releases[releases.findIndex(r => Object.keys(r)[0] === currentTab)][currentTab]?.songs?.songs)
    
                }
                   
            
          }
          setContent(c)
          setCurrentTab(prev => {return prev})
          return
    }

    return (
        <ContentWrapper>
            {isLoaded && releases.length > 0 && 
            
            <>
            <NavContainer><Nav>
                <NavLink active={currentTab === 'all'} onClick={() => setCurrentTab('all')}>All</NavLink>
                {releases?.map(artist => {
                    return <NavLink onClick={() => setCurrentTab(Object.keys(artist)[0])} active={currentTab === Object.keys(artist)[0]}>
                        {artists.filter(a => a?.artistId === Object.keys(artist)[0])[0]?.artistName}
                    </NavLink>
                })}</Nav></NavContainer>
            {currentTab === 'all' && <Slider id={'Exclusive Music'} heightScroll={true} type={SLIDER_TYPES.MISCELLANEOUS_MUSIC} slides={ () =>
            {
                let c = []
                releases.map((release) => {
                    return  Object.values(release).map(r => {
                          if(r?.albums?.length > 0){
                      c.push(...r?.albums?.albums)
                      
                  }
                  if(r?.songs?.length > 0){
                      c.push(...r?.songs?.songs)
    
                  }
                      })
              })
              return c
            }
            
            }/>}
            {currentTab !== 'all' && releases.findIndex(r => Object.keys(r)[0] === currentTab) > -1 && <Slider id={'Exclusive Music'} heightScroll={true} type={SLIDER_TYPES.MISCELLANEOUS_MUSIC} slides={() =>{
                 let c = []
                 if(releases[releases.findIndex(r => Object.keys(r)[0] === currentTab)][currentTab]?.albums?.length > 0){
                    c.push(...releases[releases.findIndex(r => Object.keys(r)[0] === currentTab)][currentTab]?.albums?.albums)
                    
                }
                if(releases[releases.findIndex(r => Object.keys(r)[0] === currentTab)][currentTab]?.songs?.length > 0){
                    c.push(...releases[releases.findIndex(r => Object.keys(r)[0] === currentTab)][currentTab]?.songs?.songs)
    
                }
                return c
            } }/>}
            </>}
            {!isLoaded && <Slider type={SLIDER_TYPES.MUSIC_SKELETON}/>}
        </ContentWrapper>
    )
}


const CommunityHub = () => {
    const {currentUser, getUserArtistSubscriptions, artistSubscriptions} = useAuth()
    const {searchArtistSubscriptionMusic, getArtistProfile} = useArtist({})
    const {openModal, closeModal, modalContent} = useModal()
    const {goTo} = ScrollToTop()

    const [filter, setFilter] = useState('7days')
    const [currentTab, setCurrentTab] = useState('subscriptions')
    const [subscriptions, setSubscriptions] = useState([])
    const [releases, setReleases] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    const [isReleasesLoaded, setReleasesLoaded] = useState(false)

useEffect(() => {
    setLoaded(false)
    setReleasesLoaded(false)
    getUserArtistSubscriptions(currentUser?.uid).then(async(subs) => {
    if(subs?.length > 0){
        let artists = []
        let process = []
        let releaseProcess = []
        let music = []
        for(let i = 0; i < subs.length; i++){
            process.push(getArtistProfile(subs[i]).then((a) => {
                if(a){
                  artists.push(filterArtistInfo(a?.artistId, {...a?.profile, ...a?.gravatarProfile}))  
                }
                
            }))
            releaseProcess.push(searchArtistSubscriptionMusic(subs[i], 'all').then(m => {
                if(m?.albums?.length > 0 || m?.songs?.length > 0){
                    music.push({[subs[i]] : m})   
                }
                
            }))
        }
        await Promise.all(process)
        await Promise.all(releaseProcess)
        setSubscriptions(artists)
        setReleases(music)
        setReleasesLoaded(true)
        setLoaded(true)


    }
    setLoaded(true)
    setReleasesLoaded(true)
  }).catch(err => {
    setLoaded(true)
    setReleasesLoaded(true)
  })
  return () => {
  }
}, [currentUser?.uid])

    
  return (
    <>
    <Header title={'Community Hub'}/>
    <ContentWrapper>
        <HubContainer>
            <HubSideBar>
                <HubMenuListWrapper isActive={currentTab === 'subscriptions'} onClick={() => setCurrentTab('subscriptions')}>
                    Artist Subscriptions
                </HubMenuListWrapper>
                <HubMenuListWrapper onClick={() => goTo('/messageHub') }>
                    Community Messenger
                </HubMenuListWrapper>
                <HubMenuListWrapper isActive={currentTab === 'releases'} onClick={() => setCurrentTab('releases')}>
                    Exclusive Releases
                </HubMenuListWrapper>
            </HubSideBar>
            <HubBodyContainer>
                    {currentTab === 'subscriptions' && <ArtistsInfo artists={subscriptions} isLoaded={isLoaded}/>}
                    {currentTab === 'releases' && <ExclusiveReleases artists={subscriptions} releases={releases} isLoaded={isReleasesLoaded}/>}
            </HubBodyContainer>

        </HubContainer>


    </ContentWrapper>
    
    </>
  )
}

export default CommunityHub