import React, { useState, useRef, useEffect } from "react";
import { FaClipboard, FaDonate, FaEnvelope, FaPlayCircle } from "react-icons/fa";
import { ArtistInfoContainer } from "../../AudioPlayer/AudioPlayerElements";
import { CustomButton } from "../../ButtonElement";
import { DEFAULT_IMAGE } from "../../GravatarAPI";
import {
  ActionButton,
  ActionButtonsContainer,
  ArtistInfoWrapper,
  ArtistName,
  ArtistProfileHeader,
  ArtistProfileHeaderContainer,
  ArtistProfilePicture,
  ArtistProfilePictureContainer,
  HeaderContainer,
  HeaderImg,
  PlayButton,
  ShuffleButton,
  VerifiedBadge,
} from "./ArtistHeaderElements";
import verifiedBadge from "../../../images/verified_artist_page.png";
import Modal from "../../Modal";
import { ImageViewer } from "../../ImageViewer";
import { useAuth } from "../../../contexts/AuthContext";
import useArtist from "../../../hooks/useArtist";
import ToolTip from "../../ToolTip";
import { ALERT_TYPES, CONTENT_TYPES, TRANSACTION_OPTIONS } from "../../../utils/Enum";
import { useAlert } from "../../Alert/AlertContext";
import { copyTextToClipboard, generateQRCode } from "../../../utils";
import OptionModal from "../../OptionModal";
import { ProfileQRCode } from "../../Profile/ProfileElements";
import { HoverOption } from "../../MenuOptions/MenuOptionsElements";
import { useModal } from "../../Modal/ModalContext";
import CryptoWalletIcons from "../../CryptoWalletIcons";
import TipForm, { DonationForm, SwerlTipForm } from "../../TipForm";
import TipWallets from "../../TipForm/TipWallets";
import StreamingSubscriptionModal from "../../StreamingSubscriptionModal";
import QRCodeForm from "../../QRCodeForm";
import { FormImg, FormImgWrapper } from "../../CustomForm/CustomFormElements";
import MusicBanner from "../../MusicBanner"
import ContentWrapper from "../../ContentWrapper";
import { getChatBySubscriberId } from "../../../utils/dbUtils/messengerUtils";
import ScrollToTop from "../../ScrollToTop";


const ArtistHeader = ({
  background,
  artist,
  artistSettings,
  discography,
  playDiscography,
  //shuffleDiscography,
  isSubscribed,
  wallets,
  artistName,
  isVerified,
  profilePicture,
  artistUid,
  artistId,
  isUserFollowing,
}) => {
  const {goTo} = ScrollToTop()
  const [isOpen, setOpen] = useState(false);
  const {toggleModal, modalContent, openModal} = useModal()
  const { addAlert } = useAlert();
  const { currentUser, getUserSubscription, isUserSubscribed, getUserArtistSubscriptions } = useAuth();
  const followRef = useRef();
  const [isFollows, setFollowing] = useState(false);
  const [chat, setChat] = useState()
  const { followArtist, unfollowArtist } = useArtist({ id: artistId });
  const openViewer = () => {
    setOpen(!isOpen);
  };
  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFollowing(isUserFollowing);
    return () => {}
  }, [artistId]);

  useEffect(() => {
    if(isSubscribed){
      getChatBySubscriberId(currentUser?.uid, artistId,setChat, () => {})
    }
    
  }, [artistId, isSubscribed, currentUser?.uid])

  //MOVE THE BUTTONS INTO CUSTOM COMPONENT CLASSES!
  const onFollowClick = () => {
    // follow and unfollow usera
    followRef.current.enabled = false;
    followArtist()
      .then((data) => {
        if (data !== false) {
          setFollowing(!isFollows);
        }
      })
      .catch((err) => {
        return;
      });
    followRef.current.enabled = true;
  };

  const onUnfollowClick = () => {
    // follow and unfollow user

    followRef.current.enabled = false;
    unfollowArtist()
      .then((data) => {
        if (data !== false) {
          setFollowing(!isFollows);
        }
      })
      .catch((err) => {
        return;
      });
    followRef.current.enabled = true;
  };

  return (
    <>
      <HeaderContainer>
        <Modal ariaHideApp={false} isOpen={isOpen} onRequestClose={closeModal}>
          <ImageViewer
           onContextMenu={(e) => {
            e.preventDefault();
          }}
            img={
              profilePicture
                ? profilePicture + "?s=600&d=mp"
                : DEFAULT_IMAGE(600)
            }
            isCircular={true}
            title={artistName}
          />
        </Modal>
        <ArtistProfileHeader
          background={background}
          headerType={artistSettings?.headerType}
        ></ArtistProfileHeader>
        <ArtistProfileHeaderContainer>
          <ArtistProfilePictureContainer>
            <ArtistProfilePicture
             onContextMenu={(e) => {
              e.preventDefault();
            }}
              onClick={openViewer}
              src={
                profilePicture ? profilePicture : DEFAULT_IMAGE(600)
              }
              alt={artistName}
            />
          </ArtistProfilePictureContainer>
          <ArtistInfoWrapper>
            <ArtistName>
              {currentUser?.uid && Object.keys(discography)?.length > 0 && (
                <>
                  <ToolTip text={"Play Discography"}><PlayButton
                    background={background}
                    onClick={async() => {
                      if (currentUser?.uid) {
                        if (!currentUser?.subscription) {
                          await getUserSubscription();
                        }
                
                        if (await isUserSubscribed()) {
                          playDiscography();
                        } else {
                          openModal();
                          modalContent(
                            <>
                              <StreamingSubscriptionModal
                                onSuccess={() => {
                                  getUserSubscription(currentUser?.uid);
                                  playDiscography();
                                  toggleModal();
                                  return modalContent(
                                    <OptionModal heading={"Subscription Enabled Sucessfully"}>
                                      <HoverOption
                                        onClick={() => {
                                          closeModal();
                                          modalContent();
                                        }}
                                      >
                                        OK
                                      </HoverOption>
                                    </OptionModal>
                                  );
                                }}
                                onFail={() => {
                                  return modalContent(
                                    <OptionModal heading={"Subscription Unsucessful"}>
                                      <HoverOption
                                        onClick={() => {
                                          closeModal();
                                          modalContent();
                                        }}
                                      >
                                        OK
                                      </HoverOption>
                                    </OptionModal>
                                  );
                                }}
                                cancel={() => {
                                  closeModal();
                                  modalContent();
                                  return 
                                }}
                              />
                            </>
                          );
                        }
                        
                      } else {
                        addAlert({
                          title: "Not Logged In",
                          type: ALERT_TYPES.WARNING,
                          message: "You need to log in to play this album",
                        });
                      }
                    }}
                  /></ToolTip>
                 
                </>
              )}
              {artistName}
              {isVerified && (
                <ToolTip text={"This account is verified"}>
                  <VerifiedBadge
                    src={verifiedBadge}
                    onContextMenu={(e) => {
                      e.preventDefault();
                    }}
                  />
                </ToolTip>
              )}
            </ArtistName>
          
            {currentUser?.uid && (
              <ActionButtonsContainer>
                  {isSubscribed && <ActionButton onClick={() => {
              if(isSubscribed && chat?.chatId){
                goTo(`/messageHub?chat=${chat?.chatId}`)
                return
              }
              if(isSubscribed){
                goTo(`/messageHub`)
                return
              }
              return
            }}>Message</ActionButton>}
                <ActionButton
                  ref={followRef}
                  background={background}
                  onClick={() =>
                    isFollows ? onUnfollowClick() : onFollowClick()
                  }
                >
                  {" "}
                  {isFollows ? "Unfollow" : "Follow"}
                </ActionButton>
                 {artistSettings?.tipping && <ActionButton background={background} onClick ={() => {
                  toggleModal()
                  modalContent(<OptionModal heading={`Select Wallet to tip ${artistName}`}>
                    {artistUid && <><HoverOption onClick={() => {
                      toggleModal()
                      modalContent(<TipForm displayName={artistName} address={artistUid}/>)
                    }}>XRP Wallet</HoverOption>
                    <HoverOption onClick={() => {
                      toggleModal()
                      modalContent()
                      addAlert({
                        title: "Coming Soon",
                        type: ALERT_TYPES.INFO,
                        secs: 10,
                      })
                    }}>ILP Wallet</HoverOption></>}
                    <HoverOption onClick={() => {
                      toggleModal()
                      modalContent(<SwerlTipForm type={CONTENT_TYPES.ARTIST} content={{artistName: artistName, artistId: artistId, address: artistUid}}/>)
                    }}>Swərl Subscription</HoverOption>
                  { wallets?.length > 0 && <HoverOption onClick={() => {
                      toggleModal()
                      modalContent(<TipWallets wallets={wallets} />)
                    }}>Other Wallets</HoverOption>}
                  </OptionModal>)}}>
                      Send Tip
                    </ActionButton>}
                {artistUid &&
                  isVerified &&
                  artist?.subscriptionFee > 0 &&
                   (
                    <ActionButton background={background} onClick={() => {
                      if(!isSubscribed){
                      openModal()
                      modalContent(<OptionModal onCancel={() => {
                        closeModal()
                        modalContent()
                      }} heading={`Subscribe to ${artistName}`} 
                      additionalText={<ContentWrapper><MusicBanner img={<FormImgWrapper>
                        <FormImg src={profilePicture
                ? profilePicture
                : DEFAULT_IMAGE(600)}/>
                        </FormImgWrapper>}
          text={<>
          Subscribing to {artistName} {artist?.subscriptionFee > 0 && `for ${artist?.subscriptionFee} ${artist?.currency}/month`} provides you with unlimited access to all the new music that they release, plus bonus items from their back-catalog, and subscriber-exclusive releases as well as an opportunity to message with them.
          </>}
          /></ContentWrapper>}>
                       <HoverOption onClick={() => {
                          modalContent(<QRCodeForm 
                            type={TRANSACTION_OPTIONS.SUBSCRIBE_TO_ARTIST}
                            heading={`Subscribe to ${artistName}`}
                            onFail={() => {closeModal()
                              modalContent()
                            }}
                            cancelQr={() => {closeModal()
                              modalContent()
                            }}
                            onSuccess={async (data) => {
                              await getUserArtistSubscriptions(currentUser?.uid)
                              modalContent(<OptionModal heading={`Subscription to ${artistName} successful`}>
                                <HoverOption onClick={() => {
                                  closeModal()
                                  modalContent()
                                }}>OK</HoverOption>
                              </OptionModal>)
                            }}
                            body={{
                              value: artist?.subscriptionFee,
                              currency: artist?.currency,
                              pathfinding: true,
                              artistId: artistId,
                              sender: currentUser?.uid,
                              memo: `${currentUser?.uid} is signing transaction to subscribe to ${artistId} for 1 Month`,
                              instruction: `${currentUser?.uid} is signing transaction to subscribe to ${artistId} for 1 Month`,
                            }}
                          />)
                        }}>
                        Continue
                       </HoverOption>
                      </OptionModal>)
                    }}}>
                      {isSubscribed ? "Subscribed" : "Subscribe"}
                    </ActionButton>
                    
                  )}
                {artistUid &&
                  isVerified &&
                  artist?.donation &&
                  (
                    <ActionButton style={{backgroundColor: 'goldenrod'}} background={background} onClick={() => {
                      toggleModal()
                      modalContent(<OptionModal heading={'Donation'} additionalText={<i>You are about to make a donation to a cause of {artistName}'s choice. To continue select the 'Donate' button</i>}>
                        <HoverOption onClick={()=> {
                          modalContent(<DonationForm displayName={artistName} cause={artist?.donation} currency={artist?.currency} address={artist?.donation?.address}/>)
                        }}>Donate</HoverOption>
                        <HoverOption>Cancel</HoverOption>
                      </OptionModal>)
                    }}>
                      <FaDonate/> Make Donation 
                    </ActionButton>
                    
                  )}
              </ActionButtonsContainer>
            )}
          </ArtistInfoWrapper>
        </ArtistProfileHeaderContainer>
      </HeaderContainer>
    </>
  );
};

export default ArtistHeader;
