import React, { useEffect, useState } from 'react'
import { MessageSideBarButton, MessageSideBarButtonContainer, MessengerBody, MessengerBodyContainer, MessengerBodyHeader, MessengerChatListContent, MessengerChatListContentPreview, MessengerChatListContentTitle, MessengerChatListImg, MessengerChatListInfo, MessengerChatListWrapper, MessengerContainer, MessengerSideBar, MessengerSideBarHeader, MessengerTextButtonWrapper, MessengerTextInputForm, MessengerTextInputWrapper, MessengerWrapper } from './MessengerElements'
import { BsEmojiSmileFill, BsSend } from 'react-icons/bs'
import { DEFAULT_IMAGE } from '../GravatarAPI'
import {useAuth} from '../../contexts/AuthContext'
import Messages, { AutoScrollDiv } from './Messages'
import { getUserChats, sendMessage } from '../../utils/dbUtils/messengerUtils'
import { ALERT_TYPES } from '../../utils/Enum'
import { useAlert } from '../Alert/AlertContext'
import { VerifiedBadge } from '../Profile/ProfileDetails/ProfileDetailsElements'
import verifiedBadge from "../../images/verified_artist_page.png";
import { ImageWrapper, Img } from '../ContentList/ContentListElements'
import { FaRegEdit } from 'react-icons/fa'
import { ContentListSkeleton, UserContentListSkeleton } from '../Skeleton'
import { Header } from '../Header'
import OptionModal from '../OptionModal'
import { useModal } from '../Modal/ModalContext'
import useArtist from '../../hooks/useArtist'
import { filterArtistInfo } from '../../utils/dbUtils/artistUtils'
import ArtistList from '../ContentList/ArtistList'
import { useUser } from '../../hooks/useUser'
import ScrollToTop from '../ScrollToTop'
import { getChatTimestamp, timestampToDate } from '../../utils'
import EmojiPicker from 'emoji-picker-react'
import { useWebsiteTheme } from '../WebsiteTheme/WebsiteThemeContext'


const Messenger = () => {
    const {currentUser, currentProfile, profiles,gravatarInfo } = useAuth()
    const {openModal, closeModal, modalContent} = useModal()
    const {addAlert} = useAlert()
    const {theme, themeColors} = useWebsiteTheme()
    const [chats, setChats] = useState([])
    const [currentChat, setCurrentChat] = useState(null)
    const [isChatsLoaded, setChatsLoaded] = useState(false)
    const [showPicker, setShowPicker] = useState(false);
    const [message, setMessage] = useState('')

    useEffect(() => {
        getUserChats(currentUser?.uid, setChats, setChatsLoaded)
    
      return () => {
        
      }
    }, [])

    useEffect(() => {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        if (isChatsLoaded) {
          if (params.get("chat")) {
            setCurrentChat(params.get("chat"));
          }
        }
    
        return () => {};
      }, [isChatsLoaded]);
    
      const onEmojiClick = (emojiObject) => {
        if(currentUser?.artistSubscriptions.some(a => a === chats.filter(c => c?.chatId === currentChat)[0]?.artist?.artistId)){
        setMessage((prevText) => prevText?.length > 0 ? prevText + emojiObject.emoji : emojiObject.emoji); 
    }
        // Optionally close the picker after selection
      };

  return (
    <><Header title={'Message Hub'}/>
        <MessengerWrapper>
            <MessengerContainer> 
                <MessengerSideBar>
                <MessageSideBarButtonContainer>
                <MessageSideBarButton onClick={() => {
                    openModal()
                    modalContent(<CurrentSubscriberChatList setChat={setCurrentChat}/>)
                }}>
              <FaRegEdit />
            </MessageSideBarButton>
            </MessageSideBarButtonContainer>
                <MessengerSideBarHeader>
            <ImageWrapper>
              <Img isCircular={true}
                src={
                  currentUser?.photoURL + "?s=600&d=mp"
                }
              />
            </ImageWrapper>{" "}
            {gravatarInfo?.display_name ? gravatarInfo?.display_name : `${currentUser?.uid?.substring(0, 6)}...` }
            <FaRegEdit style={{cursor: 'pointer'}} onClick={() => {
                    openModal()
                    modalContent(<CurrentSubscriberChatList/>)
                }}/>
          </MessengerSideBarHeader>
                    {chats?.length > 0 && isChatsLoaded && chats.map((chat, id) => {
                        return(
                        <MessengerChatListWrapper isActive={currentChat === chat?.chatId} id={id} onClick={() => {
                            setCurrentChat(chat?.chatId)
                        }}>
                       <MessengerChatListImg src={chat?.artist?.artistSettings?.profilePictureType === "custom" && chat?.artist?.profilePicture ?
        chat?.artist?.profilePicture :
        chat?.artist?.photoUrl
          ? chat?.artist.photoUrl + "?s=600&d=mp"
          : DEFAULT_IMAGE(600)}/>
                       <MessengerChatListContent> 
                        <MessengerChatListContentTitle>{chat?.artist?.artistName}</MessengerChatListContentTitle> 
                        <MessengerChatListContentPreview>{chat?.lastMessage?.message}</MessengerChatListContentPreview>
                       </MessengerChatListContent>
                       <MessengerChatListInfo>
                        <div>{getChatTimestamp(new Date(timestampToDate(chat?.lastMessage?.dateCreated)))}</div>
                        
                       </MessengerChatListInfo>
                       
                    </MessengerChatListWrapper>
                    )})}
                    {!isChatsLoaded && <UserContentListSkeleton/>}
                    
                </MessengerSideBar>
                <MessengerBodyContainer>
                    {currentChat && <MessengerBodyHeader>
                    <ImageWrapper> <Img src={chats.filter(c => c?.chatId === currentChat)[0]?.artist?.artistSettings?.profilePictureType === "custom" && chats.filter(c => c?.chatId === currentChat)[0]?.artist?.profilePicture ?
        chats.filter(c => c?.chatId === currentChat)[0]?.artist?.profilePicture :
        chats.filter(c => c?.chatId === currentChat)[0]?.artist?.photoUrl
          ? chats.filter(c => c?.chatId === currentChat)[0]?.artist.photoUrl + "?s=600&d=mp"
          : DEFAULT_IMAGE(600)}/></ImageWrapper>

<div>{chats.filter(c => c?.chatId === currentChat)[0]?.artist?.artistName}</div>
{chats.filter(c => c?.chatId === currentChat)[0]?.artist?.isVerified && <VerifiedBadge src={verifiedBadge}/>}

                    </MessengerBodyHeader>}
                <AutoScrollDiv chatId={currentChat}>
                    <Messages chatId={currentChat}/></AutoScrollDiv>
                    {showPicker && <div style={{display: "flex", position: 'absolute', flexDirection: 'column', backgroundColor: themeColors?.mainBg, borderRadius: '15px'}}><div onClick={() => setShowPicker(false)} style={{padding: '2%', cursor: 'pointer'}}>X</div>
          <EmojiPicker style={{backgroundColor: 'transparent', border: 'none'}} theme={theme} onEmojiClick={onEmojiClick}/>
          </div>}
                <MessengerTextInputWrapper>
                    <MessengerTextInputForm value={message} 
                    readOnly={!currentUser?.artistSubscriptions.some(a => a === chats.filter(c => c?.chatId === currentChat)[0]?.artist?.artistId)}
                    onChange={(e) => {
                        setMessage(e.target.value)
                    }} placeholder='Write a message...'/>
                    <MessengerTextButtonWrapper>
                    <BsEmojiSmileFill color={showPicker && themeColors?.accentColor} style={{border: showPicker && `2px solid ${themeColors?.mainBg}`, borderRadius: '50%'}} onClick={() => setShowPicker(!showPicker)}/>
                        <BsSend onClick={async () => {
                            if(message?.trim()?.length > 0 && currentUser?.artistSubscriptions.some(a => a === chats.filter(c => c?.chatId === currentChat)[0]?.artist?.artistId)){
                                let m = message
                                setMessage('')
                                await sendMessage(currentUser?.uid,currentChat, m, (error) => {
                                    addAlert({title: 'Message Error',
                                        type: ALERT_TYPES.WARNING,
                                        message: error
                                    })
                                    setMessage(m)
                                } ).then(() => {
                                    setMessage('')
                                }).catch((err) =>{
                                    addAlert({title: 'Message Error',
                                        type: ALERT_TYPES.WARNING,
                                        message: err.message
                                    })
                                })
                                setMessage(m)
                                return
                            }
                            if(!currentUser?.artistSubscriptions.some(a => a === chats.filter(c => c?.chatId === currentChat)[0]?.artist?.artistId)){
                                addAlert({
                                    title: 'Message Error',
                                    type: ALERT_TYPES.WARNING,
                                    message: 'Not Subscribed to Artist'
                                })
                                return
                            }
                        }}/>
                    </MessengerTextButtonWrapper>
                </MessengerTextInputWrapper>
                </MessengerBodyContainer>
            </MessengerContainer>
        </MessengerWrapper>  </>  
  )
}


export const CurrentSubscriberChatList = ({setChat}) => {
    const {currentUser} = useAuth()
    const {goTo} = ScrollToTop()
    const {getUserArtistSubscriptions} = useUser({})
    const [subscriptions, setSubscriptions] = useState([])
    const [isLoaded, setLoaded] = useState(false)
    const {openModal, closeModal, modalContent} = useModal()

    useEffect(() => {
      setLoaded(false)
        getUserArtistSubscriptions(currentUser?.uid).then(async (subs) => {
            if(subs?.artistsSubscriptions?.length > 0){
            setSubscriptions(subs?.artistsSubscriptions)
        }
        setLoaded(true)
        })
      return () => {
        
      }
    }, [])
    


    return (
        <OptionModal onCancel={() => {
            closeModal()
            modalContent()
        }}>
           {!isLoaded &&  <UserContentListSkeleton/>}
           {isLoaded && subscriptions?.length > 0 && <ArtistList content={subscriptions} onButtonClick={(artist) => {
            goTo(`/messageHub?chat=${artist?.chatInfo?.chatId}`)
            setChat(artist?.chatInfo?.chatId)
            closeModal()
            modalContent()
           }}/>}
        </OptionModal>
    )
}

export default Messenger