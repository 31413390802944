export const tsAndCs = [{
    title: 'Introduction',
    text: 'These Terms and Conditions ("Terms") govern your use of the Sonar Muse platform and services (collectively, the "Platform"), provided by Sonar Muse. By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Platform.'
},
{
    title: 'Definitions',
    points: [
    `Needle Time Model: A royalty payout system where artists are compensated based on the amount of time their music is streamed.`,
    `Music NFT: A non-fungible token representing ownership of a digital music release.`,
    `Tokenized Royalties: Digital tokens representing a share of royalties earned from music streams.`,
    `XRPL: The XRP Ledger, a blockchain used by Sonar Muse for certain transactions.`,
    `Gravatar: A globally recognized avatar system used for profile customization on Sonar Muse.`]
},
{
    title: 'Use of the Platform',
    texts: [
        'Eligibility: You must be at least 18 years old to use the Platform.',
        `Account Creation: You may be required to create an account to access certain features of the Platform. You are responsible for maintaining the confidentiality of your account credentials and for any activity that occurs under your account.`,
        `Prohibited Conduct: You agree not to:`
    ],
    points: [
        `Use the Platform for any unlawful purpose or in any manner inconsistent with these Terms.`,
        `Use the Platform to upload, transmit, or distribute any content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.`,
        `Use the Platform to upload, transmit, or distribute any copyrighted material without the proper authorization.`,
       `Use the Platform to impersonate any person or entity, or to falsely claim an affiliation with any person or entity.`,
       `Interfere with the proper functioning of the Platform.`,
       `Attempt to gain unauthorized access to any portion of the Platform or any accounts other than your own.`,
       `Use any automated means, such as scripts, robots, or crawlers, to access or interact with the Platform.` 
    ]
},
{
    title: 'User Content',
    points: [
        `Ownership: You retain ownership of any content you upload, transmit, or distribute on the Platform ("User Content").`,
        `Grant of License: By uploading User Content, you grant Sonar Muse a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Content in connection with the Platform and Sonar Muse's business.` ,
        `Representations and Warranties: You represent and warrant that you have all necessary rights, licenses, and permissions to upload User Content and grant the license set forth above.`
        ]

},
{
    title: 'Intellectual Property',
    points: [
        `Sonar Muse Rights: The Platform and all content on the Platform, including but not limited to text, images, logos, and software, are owned by or licensed to Sonar Muse and are protected by copyright, trademark, and other intellectual property laws.`,
        `User Content: You retain ownership of any content you upload, transmit, or distribute on the Platform ("User Content"). However, by uploading, transmitting, or distributing User Content, you grant Sonar Muse a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such User Content in connection with the Platform and Sonar Muse's business.`,
        `Third-Party Content: The Platform may contain links to third-party websites and services. Sonar Muse is not responsible for the content or availability of any third-party websites or services.`
    ]
},
{
    title: 'Music NFTs, Digital Releases, and Tokenized Royalties',
    points: [
        `Ownership and Licensing: When you mint or purchase a music NFT, digital release, or tokenized royalty on the Platform, you acquire ownership of the specific digital asset. However, you may not reproduce, distribute, or publicly perform the underlying musical work without obtaining the necessary licenses from the rights holders.`,
        `Royalty Payments: Sonar Muse utilizes the needle time model to calculate and distribute royalties to rights holders. Royalties for streams, downloads, and other uses of the music will be paid out in cryptocurrency, such as XRP, based on the actual usage of the music.`,
        `Tokenized Royalties: Tokenized royalties represent ownership of a portion of the future royalties generated by a specific musical work. These tokens can be bought, sold, and traded on the Platform.`
    ]
},
{
    title: 'Gravatar Integration',
    points: [
        `Profile Customization: Sonar Muse integrates with Gravatar to allow users to customize their profiles with images from their Gravatar accounts.`,
        `Data Privacy: By using Gravatar, you acknowledge and agree to Gravatar's terms of service and privacy policy.`
    ]
},
{
    title: 'Disclaimer of Warranties',
    bold_text: `THE PLATFORM IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. SONAR MUSE DOES NOT WARRANT THAT THE PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE PLATFORM OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.`
},
{
    title: 'Limitation of Liability',
    bold_text: `IN NO EVENT SHALL SONAR MUSE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO LOSS OF PROFITS, DATA LOSS, OR LOSS OF GOODWILL, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE PLATFORM, EVEN IF SONAR MUSE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.`
},
{
    title: 'Indemnification',
    text: `You agree to indemnify and hold harmless Sonar Muse and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, and expenses, including attorneys' fees, arising out of or in connection with your use of the Platform or your violation of these Terms.`
},
{
    title: 'Termination',
    text: `Sonar Muse may terminate or suspend your access to the Platform at any time, without notice, for any reason, including but not limited to violation of these Terms.`
},
{
    title: 'Governing Law',
    text: `These Terms shall be governed by and construed in accordance with the laws of South Africa, without regard to its conflict of law provisions.`
},
{
    title: 'Changes to These Terms',
    text: 'Sonar Muse may update these Terms from time to time. We will notify you of any material changes by posting a notice on the Platform or by sending you an email. Your continued use of the Platform after the effective date of any such changes constitutes your acceptance of the new Terms.'
},
{
    title: 'Contact',
    text:`If you have any questions about these Terms, please contact us at info@sonsarmuse.org.`
},
]

export const privacyPolicy = [
    {
    title: 'Information We Collect',
    text: `Sonar Muse collects limited personal information to provide and improve our services. Specifically, we collect:`,
    points: [
        `Wallet Information: We store your XRPL wallet address to enable access to our platform and facilitate transactions related to music ownership and distribution.`,
        `Gravatar Information (Selective): We utilize Gravatar services to display user profile pictures. We only access information that you have publicly associated with your Gravatar account, such as your profile picture. We do not collect or store any other information from Gravatar.`
        ]
},
    {
    title: 'Use of Information',
    text: 'We use the collected information for the following purposes:',
    points: [
        `Platform Functionality: Your wallet address is used to authenticate your access, manage your music library, and execute transactions on the XRPL.`,
`User Identification: Gravatar profile pictures are used to visually identify users on the platform.`,
`Service Improvement: We may analyze aggregated and anonymized data to improve the platform's functionality and user experience.`,
`Security: We use wallet information to help ensure the security of the platform and prevent fraudulent activity.`
    ]
},
    {
    title: 'Data Storage and Security',
    points: [`Your XRPL wallet address is stored securely on our servers.`,
`We implement reasonable security measures to protect your information from unauthorized access, use, or disclosure.`,
`We do not store your private keys. Your private keys stay within your own wallet.`,
`We use industry standard encryption protocols.`,
`We will never ask you for your private key.`]
},
    {
    title: 'Information Sharing',
    points: [
        `We do not share your wallet address or any other personal information with third parties except as required by law or to comply with legal processes.`,
`Gravatar images are shared with other users of the platform, as they are by design a public service.`,
`In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction. You will be notified of any such change.
`]
},
    {
    title: 'Data Retention',
    points: [`We retain your wallet address for as long as your account is active or as needed to provide you with our services.`,
`If you delete your account, we may retain certain information as required by law or for legitimate business purposes.
`]
},
    {
    title: 'User Rights',
    points: [`You have the right to access and review the information we hold about you.`,
`You can delete your account at any time, which will remove your wallet address from our active database.`,
`As we use Gravatar, changes to your Gravatar profile are managed through the Gravatar service itself.
`]
},
    {
    title: 'Third-Party Services',
    points: [`Our platform uses Gravatar for profile pictures. Your use of Gravatar is subject to Gravatar's privacy policy.`,
        `Our platform utilizes the XRPL. Your use of the XRPL is subject to the XRPL's privacy policy.`]
},
    {
    title: 'Changes to this Privacy Policy',
    points: [`We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated policy on our website.`, 
`Your continued use of the platform after any changes constitutes your acceptance of the updated policy.   
`]
},
    {
    title: 'Contact Us',
    text: 'If you have any questions or concerns about this Privacy Policy, please contact us at: info@sonarmuse.org'
},
]

export const antiFraudPolicy = [
    {
        title: `Introduction`,
        text: `Sonar Muse ("the Platform") is committed to maintaining a secure and transparent environment for artists, fans, and all users within our Web3 music platform built on the XRP Ledger (XRPL). This Anti-Fraud Policy outlines our commitment to preventing, detecting, and mitigating fraudulent activities that may compromise the integrity of the Platform.`
    },
    {
        title: `Scope`,
        text: `This policy applies to all users of Sonar Muse, including but not limited to:`,
        points: [
            `Artists`,
            `Fans`,
            `Collectors`,
            `Developers`,
            `Employees`,
            `Partners`]
    },
    {
        title: `Prohibited Activities`,
        text: `The following activities are strictly prohibited on Sonar Muse:`,
        points: [{
            text: `Identity Fraud:`,
            points: [
                `Creating fake accounts or impersonating other users.`,
                `Providing false or misleading information during registration or verification.`,
                `Using stolen or fraudulent identification documents.`
            ]
        },
    {
        text: `Content Fraud:`,
        points: [
            `Uploading or distributing copyrighted material without proper authorization.`,
`Falsifying streaming or download counts to artificially inflate popularity or earnings.`,
`Creating and distributing fake or misleading metadata for music NFTs.`,
`Creating AI music and claiming it as original work without proper disclosure.`
        ]
    },
    {
        text: `Transaction Fraud:`,
        points: [
            `Engaging in wash trading or other forms of market manipulation.`,
`Using stolen or unauthorized payment methods.`,
`Attempting to reverse legitimate transactions or initiate chargebacks without valid reasons.`,
`Exploiting smart contract vulnerabilities.`
        ]
    },
    {   text: `Phishing and Scams:`,
        points: [
            `Distributing phishing links or malicious software.`,
`Engaging in social engineering tactics to obtain user credentials or private keys.`,
`Promoting fraudulent investment schemes or giveaways.`
        ]
    },
    {
        text: `Spam and Bot Activity:`,
        points: [`Using bots or automated scripts to artificially inflate engagement or activity.`,
`Sending unsolicited messages or spam to other users.`,
`Using bots to manipulate NFT drops.`]
    },
    {
      text: `XRPL Manipulation:`,
      points: [`Any activities that attempt to manipulate the XRPL ledger to gain unfair advantage.`,
`Exploiting bugs within the XRPL or associated protocols.`]  
    }

]
    },
    {
        title: `Fraud Detection and Prevention`,
        text: `Sonar Muse employs various measures to detect and prevent fraudulent activities, including:`,
        points: [`KYC/AML Procedures: Implementing robust Know Your Customer (KYC) and Anti-Money Laundering (AML) procedures for user verification.`,
`Transaction Monitoring: Monitoring transactions for suspicious patterns and anomalies.`,
`Content Verification: Utilizing content recognition and metadata analysis tools to identify copyright infringement and content fraud.`,
`Behavioral Analysis: Analyzing user behavior to identify suspicious activity and bot activity.`,
`Smart Contract Audits: Conducting regular security audits of smart contracts to identify and address vulnerabilities.`,
`Community Reporting: Providing a mechanism for users to report suspected fraudulent activity.`,
`IP and Device Tracking: Tracking IP addresses and device information to identify suspicious patterns.`,
`Two Factor Authentication: Encouraging and implementing 2FA for all accounts.`]
    },
    {
        title: `Reporting Fraud`,
        text: `Users are encouraged to report any suspected fraudulent activity to Sonar Muse through the following channels:`,
        points: [
            `Email: info@sonarmuse.org`,
/* `In-Platform Reporting: [Insert In-Platform Reporting Mechanism]`, */
{text: `When reporting fraud, please provide as much detail as possible, including:`,
points: [`Date and time of the incident.`,
`Description of the fraudulent activity.`,
`Usernames or addresses involved.`,
`Any supporting evidence (screenshots, transaction IDs, etc.).`]}]
    },
    {
        title: `Consequences of Fraudulent Activity`,
        text: `Sonar Muse reserves the right to take appropriate action against users who engage in fraudulent activities, including but not limited to:`,
        points: [`Account suspension or termination.`,
`Freezing of funds or assets.`,
`Reporting fraudulent activity to law enforcement.`,
`Legal action.`,
`Blacklisting of XRPL addresses.`,
`Removal of content.`]
    },
    {
        title: `Policy Updates`,
        text: `Sonar Muse reserves the right to update this Anti-Fraud Policy at any time. Users will be notified of any significant changes.`
    },
    {
        title: `Disclaimer`,
        text: `While Sonar Muse takes every reasonable precaution to prevent fraud, we cannot guarantee complete protection. Users are responsible for protecting their own accounts and assets.`
    },
    {
        title: `Contact Information`,
        texts: [`For any questions or concerns regarding this Anti-Fraud Policy, please contact us at:`,
            `Email: info@sonarmuse.org`]
    },

]

export const cookiePolicy = [
    {text: `Welcome to Sonar Muse, a Web3 music platform built on the XRP Ledger (XRPL). This Cookie Preferences Policy explains how we use cookies and similar technologies to enhance your experience on our platform. By accessing and using Sonar Muse, you agree to the terms outlined in this policy.`},
    {title: `What are Cookies?`,
    text: `Cookies are small text files that are placed on your device (computer, tablet, or mobile phone) when you visit a website. They are widely used to make websites work or work more efficiently, as well as to provide information to the owners of the site.`
    },
    {title: `How Sonar Muse Uses Cookies`,
        text: `Sonar Muse uses cookies for essential functionality and to enhance user experience within the limitations imposed by our data handling approach. Specifically:`,
        points: [{text: `Wallet Information:`,
            points: [`We store your XRPL wallet address to enable core functionalities such as connecting to the platform, accessing your music library, and conducting transactions. This data is essential for the platform's operation.`,
                `This data is only stored locally on your device or via your connected wallet service, and is not stored on Sonar Muse servers.`
            ]
        },
        {text: `Gravatar Email Hash:`, 
            points: [`We utilize the Gravatar email hash derived from your XRPL wallet address to retrieve publicly available Gravatar profile information. This may include profile pictures and other publicly shared details.`,
                `This is used to personalize your experience and to display publicly available profile information associated with your wallet.`,
                `We do not store your email address, only the hashed version.`
            ]
        },
        {text: `Session Cookies:`,
            points: [`We may use session cookies to maintain your session and remember your preferences during your visit. These cookies are temporary and are deleted when you close your browser.`,
                `These cookies are essential for the operation of the platform.`,
            ]
        }
    ]
    },
    {title: `Disclaimer Regarding User Information`,
        points: [`Sonar Muse operates with a strong commitment to user privacy. We do not collect or store personally identifiable information beyond your XRPL wallet address and the Gravatar email hash.`,
            `The Gravatar information displayed is publicly available and is retrieved directly from Gravatar's servers. We have no control over the content displayed.`,
            `By using Sonar Muse, you acknowledge that you understand and accept the limitations of our data handling practices.`
        ]
    },
    {title: `Your Cookie Preferences`,
        points: [`Due to the nature of our platform, which relies on your wallet connection and Gravatar hash, disabling essential cookies may significantly impact your ability to use Sonar Muse.`,
            `Since we do not use cookies for tracking or advertising purposes, there are no optional cookies to manage.`,
            `Most web browsers allow you to manage your cookie preferences through their settings. You can typically find these settings in the "Options" or "Preferences" menu of your browser. However, disabling all cookies may prevent you from using the platform.`
        ]
    },
    {title: `Changes to this Policy`,
        text:  `We may update this Cookie Preferences Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this policy periodically for any updates. Changes will be effective immediately upon posting.   
`
    },
    {title: `Contact Us`,
        texts: [`If you have any questions about this Cookie Preferences Policy, please contact us at:`,
            `info@sonarmuse.org`,
            `By continuing to use Sonar Muse, you acknowledge that you have read and understood this Cookie Preferences Policy and agree to its terms.`
        ]
    },

]